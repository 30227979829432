/*import av3 from 'assets/img/team/2.jpg';
import yazmin from 'assets/img/team/yazmin.jpg';
import alfonso from 'assets/img/team/alfonso.jpg';*/


import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Form } from 'react-bootstrap';

const FeedCardFooter = ({ submitComment, comentario, setComentario, procesandoComentario, idUsuario }) => {

    return (
        <Card.Footer className="bg-light pt-0">

            <Form id="comment" onSubmit={submitComment}>
                <Flex alignItems="center" className="border-top border-200 pt-3">
                    <Avatar
                        src={"/img/team/team_" + idUsuario + ".jpg"}
                        size="xl" />
                    <Form.Control
                        as="textarea"
                        //type="text"
                        className=" ms-2 fs--1"
                        placeholder="Escribe un comentario..."
                        rows={3}
                        style={{resize:"none"}}
                        value={comentario}
                        maxLength={999}
                        minLength={1}
                        disabled={procesandoComentario}
                        onChange={e => setComentario(e.target.value)}
                        onBlur={
                            (/*e*/) => {
                                //console.log('Triggered because this input lost focus');
                                //submitComment(e);
                            }
                        }
                        onKeyDown={
                            (e) => {

                                if (e.key === 'Enter') {
                                    submitComment(e);
                                }
                                
                               // console.log(e);
                                
                            }
                        }
                    />
                    
                </Flex>
                
            </Form>
        </Card.Footer>
    );
};

FeedCardFooter.propTypes = {
    submitComment: PropTypes.func,
    procesandoComentario: PropTypes.bool,
    comentario: PropTypes.string,
    setComentario: PropTypes.func,
    idUsuario: PropTypes.number
};


export default FeedCardFooter;
