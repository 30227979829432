import React, { useEffect, useState, forwardRef } from 'react';
import useToken from 'hooks/useToken';
import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Card, Spinner, Col, Row, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StatisticsCard from './StatisticsCard';


import bgNoInteresados from 'assets/img/icons/spot-illustrations/corner-1.png';
import bgSeguimientoLeads from 'assets/img/icons/spot-illustrations/authentication-corner.png';
import bgContactoInicial from 'assets/img/icons/spot-illustrations/corner-5.png';
import bgProspectos from 'assets/img/icons/spot-illustrations/corner-2.png';
import bgPotencial from 'assets/img/icons/spot-illustrations/corner-4.png';
import bgClienteGanado from 'assets/img/icons/spot-illustrations/corner-3.png';
import bgWave from 'assets/img/illustrations/bg-wave.png';

import iconNoInteresados from 'assets/img/support-desk/unassigned.png';
import iconCLientesGanados from 'assets/img/support-desk/reports/7.png';
import iconProspectos from 'assets/img/illustrations/comment-active.png';
import iconContactoInicial from 'assets/img/illustrations/comment-inactive.png';
import iconPotenciales from 'assets/img/illustrations/like-active.png';
import iconSeguimiento from 'assets/img/icons/spot-illustrations/3.svg';
import iconRecompraProceso from 'assets/img/support-desk/reports/4.png';


//import { getPastDates} from 'helpers/utils';

const DailyStatistics = ({
    setMensajeError,
    setShowMensajeError,
    vendedor

}) => {

    const { token, setToken} = useToken();
    const [cargando, setCargando] = useState(true);

    const [dailyStatistics, setdailyStatistics] = useState([]);


    const date = new Date();

    const [startDate, setStartDate] = useState(date.setDate(date.getDate()));
    const [endDate, setEndDate] = useState(new Date());


    const [startDateStt, setstartDateStt] = useState(new Date());
    const [endDateStt, setEndDateStt] = useState(new Date());

    useEffect(
        () => {
            //setCargando(true)
            getDailyStatistics(startDate, endDate);
            //console.log("12_pastDates", getPastDates(12));
        }
        , []);
    

    const getDailyStatistics = async (start, end) => {
        setCargando(true);

        //var today = new Date();
        //today.setDate(new Date() - 30);
        var _startDate_ = new Date(start).toLocaleDateString('es-MX', {
            year: 'numeric', month: '2-digit', day: '2-digit', hour12: false
        });

        var _endDate_ = new Date(end).toLocaleDateString('es-MX', {
            year: 'numeric', month: '2-digit', day: '2-digit', hour12: false
        });

        console.log("StratDate", _startDate_);
        console.log("end", _endDate_);

        var jDataSend = {};
        jDataSend.startDate = _startDate_;
        jDataSend.endDate = _endDate_;
        jDataSend.vendedor = vendedor;

        const response = await fetch("/api/leads/getDailyStatistics/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        //console.log("response: ", response);
        if (response.ok) {
            console.log("==== DAILY STATISTICS ====");

           
            const data = await response.json();
            console.log("data DAILY STATISTICS: ", data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);
                console.log("Detalles DAILY STATISTICS: ", ObjDetalles);

                var DailyStatisticsVar = {};
                DailyStatisticsVar.Seguimiento = ObjDetalles.table2[0].TotalFollowingLeads;
                DailyStatisticsVar.SeguimientoPromedio = ObjDetalles.table2[0].AVGFollowingLeads;

                //Leads por etapas
                ObjDetalles.table3.forEach((item) => {
                    if (item.idEtapa == 1)
                        DailyStatisticsVar.ContactoInicial = item.RegisteredLeads;

                    if (item.idEtapa == 2)
                        DailyStatisticsVar.Prospecto = item.RegisteredLeads;

                    if (item.idEtapa == 3)
                        DailyStatisticsVar.Potencial = item.RegisteredLeads;

                    if (item.idEtapa == 4)
                        DailyStatisticsVar.ClienteGanado = item.RegisteredLeads;

                    if (item.idEtapa == 5)
                        DailyStatisticsVar.NoInteresado = item.RegisteredLeads;

                    if (item.idEtapa == 6)
                        DailyStatisticsVar.RecompraEnProceso = item.RegisteredLeads;

                    if (item.idEtapa == 7)
                        DailyStatisticsVar.RecompraSatisfactoria = item.RegisteredLeads;

                });

                DailyStatisticsVar.OrdenesNuevas = 0;
                DailyStatisticsVar.OrdenesRecompra = 0;

                //Ordenes nuevas y recompra
                ObjDetalles.table4.forEach((item) => {
                    if (item.bIsRecompra == 0)
                        DailyStatisticsVar.OrdenesNuevas = item.numOrdenes;

                    if (item.bIsRecompra == 1)
                        DailyStatisticsVar.OrdenesRecompra = item.numOrdenes;

                });


                setstartDateStt(start);
                setEndDateStt(end);

                console.log(DailyStatisticsVar);
                setdailyStatistics(DailyStatisticsVar);

                setCargando(false);

            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

        }
        else {
           

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

    }

    const onChange = dates => {
        const [start, end] = dates;

        
        setStartDate(start);
        setEndDate(end);

        if (start != null && end != null) {
            
            getDailyStatistics(start, end);
        }

        console.log(dates);
    };

    const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
        <div className="position-relative">
            <Form.Control
                size="sm"
                ref={ref}
                onClick={onClick}
                value={value}
                className="ps-4"
                onChange={e => {
                    console.log({ e });
                }}
            />
            <FontAwesomeIcon
                icon="calendar-alt"
                className="text-primary position-absolute top-50 translate-middle-y ms-2"
            />
        </div>
    ));
    GreetingsDateInput.propTypes = {
        value: PropTypes.string,
        onClick: PropTypes.func
    };

    return (
        <>
            {
                cargando ?
                <>
                    
                    <Row>
                        <Col>
                            <h5><Spinner className='text-inline mt-3 ms-2' animation='border' variant='primary' /> Cargando... Daily statistics </h5>
                        </Col>
                        <Col md="auto" className="mb-3 mb-md-0">
                            <Row className="g-3 gy-md-0 h-100 align-items-center">
                                <Col xs="auto">
                                    <h6 className="text-700 mb-0 text-nowrap">Mostrando datos entre:</h6>
                                </Col>
                                <Col md="auto">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={onChange}
                                        startDate={startDate}
                                        formatWeekDay={day => day.slice(0, 3)}
                                        endDate={endDate}
                                        selectsRange
                                        dateFormat="MMM dd"
                                        customInput={<GreetingsDateInput />}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>

                            <Col className="col-3">
                                <Skeleton height={100} style={{ "zIndex": "1" }} />
                            </Col>
                            <Col className="col-3">
                                <Skeleton height={100} style={{ "zIndex": "1" }} />
                            </Col>
                            <Col className="col-3">
                                <Skeleton height={100} style={{ "zIndex": "1" }} />
                            </Col>
                            <Col className="col-3">
                                <Skeleton height={100} style={{ "zIndex": "1" }} />
                            </Col>

                    </Row>
                              
                    
                </>
                :
                <>
                        <Row>

                            <Col className="col-12">

                                <Card>
                                    <Card.Header>
                                        <Row className="flex-between-center g-0">
                                            <Col xs="auto">
                                                <h6 className="mb-0">Daily statistics</h6>
                                            </Col>
                                            
                                           
                                            <Col md="auto" className="mb-3 mb-md-0">
                                                <Row className="g-3 gy-md-0 h-100 align-items-center">
                                                    <Col xs="auto">
                                                        <h6 className="text-700 mb-0 text-nowrap">Mostrando datos entre:</h6>
                                                    </Col>
                                                    <Col md="auto">
                                                        <DatePicker
                                                           
                                                            selected={startDate}
                                                            onChange={onChange}
                                                            startDate={startDate}
                                                            formatWeekDay={day => day.slice(0, 3)}
                                                            endDate={endDate}
                                                            selectsRange
                                                            dateFormat="MMM dd"
                                                            customInput={<GreetingsDateInput />}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>

                                           
                                        </Row>

                                    </Card.Header>
                                    <Card.Body className="pe-xxl-0">

                                        <Row>
                                            <Col sm={12} md={6} lg={4}>
                                                <StatisticsCard stat={{
                                                    title: 'Seguimiento Leads',
                                                    value: dailyStatistics.Seguimiento,
                                                    decimal: false,
                                                    suffix: ' / AVG ' + dailyStatistics.SeguimientoPromedio,
                                                    prefix: '',

                                                    valueClassName: dailyStatistics.SeguimientoPromedio < 25 ? 'text-danger' :
                                                        dailyStatistics.SeguimientoPromedio >= 25 && dailyStatistics.SeguimientoPromedio < 49 ? 'text-warning' : 'text-success',

                                                    badgeBg: dailyStatistics.SeguimientoPromedio < 25 ? 'danger' :
                                                        dailyStatistics.SeguimientoPromedio >= 25 && dailyStatistics.SeguimientoPromedio < 49 ? 'warning' : 'success',

                                                    imgLic: iconSeguimiento,

                                                    badgeText: new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date().toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? 'Hoy' : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) + ' - ' + new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }),

                                                    image: bgSeguimientoLeads,
                                                    className: "mt-1"
                                                }} style={{ minWidth: '12rem' }} />

                                            </Col>

                                            <Col sm={12} md={6} lg={4}>
                                                <StatisticsCard stat={{
                                                    title: 'Contacto Inicial',
                                                    value: dailyStatistics.ContactoInicial,
                                                    decimal: false,
                                                    suffix: '',
                                                    prefix: '',
                                                    valueClassName: 'text-secondary',
                                                    badgeBg: 'secondary',
                                                    imgLic: iconContactoInicial,


                                                    badgeText: new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date().toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? 'Hoy' : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) + ' - ' + new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }),

                                                    image: bgContactoInicial,
                                                    className: "mt-1"
                                                }} style={{ minWidth: '12rem' }} />

                                            </Col>

                                            <Col sm={12} md={6} lg={4}>
                                                <StatisticsCard stat={{
                                                    title: 'Prospectos',
                                                    value: dailyStatistics.Prospecto,
                                                    decimal: false,
                                                    suffix: '',
                                                    prefix: '',
                                                    valueClassName: 'text-primary',
                                                    badgeBg: 'primary',


                                                    badgeText: new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date().toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? 'Hoy' : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) + ' - ' + new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }),

                                                    imgLic: iconProspectos,
                                                    image: bgProspectos,
                                                    className: "mt-1"
                                                }} style={{ minWidth: '12rem' }} />

                                            </Col>

                                            <Col sm={12} md={6} lg={4}>
                                                <StatisticsCard stat={{
                                                    title: 'Potencial',
                                                    value: dailyStatistics.Potencial,
                                                    decimal: false,
                                                    suffix: '',
                                                    prefix: '',
                                                    valueClassName: 'text-info',
                                                    badgeBg: 'info',


                                                    badgeText: new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date().toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? 'Hoy' : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) + ' - ' + new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }),

                                                    imgLic: iconPotenciales,
                                                    image: bgPotencial,
                                                    className: "mt-1"
                                                }} style={{ minWidth: '12rem' }} />

                                            </Col>

                                            <Col sm={12} md={6} lg={4}>
                                                <StatisticsCard stat={{
                                                    title: 'Clientes Ganados',
                                                    value: dailyStatistics.ClienteGanado,
                                                    decimal: false,
                                                    suffix: ' vs ' + dailyStatistics.OrdenesNuevas + ' órdenes',
                                                    prefix: '',
                                                    valueClassName: 'text-success',
                                                    badgeBg: 'success',


                                                    badgeText: new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date().toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? 'Hoy' : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) + ' - ' + new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }),

                                                    imgLic:iconCLientesGanados,
                                                    image: bgClienteGanado,
                                                    className: "mt-1"
                                                }} style={{ minWidth: '12rem' }} />

                                            </Col>

                                            <Col sm={12} md={6} lg={4}>
                                                <StatisticsCard stat={{
                                                    title: 'No interesado',
                                                    titleClassName: 'text-danger fw-bold',
                                                    value: dailyStatistics.NoInteresado,
                                                    decimal: false,
                                                    suffix: '',
                                                    prefix: '',
                                                    valueClassName: 'text-danger',
                                                    badgeBg: 'danger',


                                                    badgeText: new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date().toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? 'Hoy' : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) + ' - ' + new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }),

                                                    imgLic: iconNoInteresados,
                                                    image: bgNoInteresados,
                                                    className: "mt-1"
                                                }} style={{ minWidth: '12rem' }} />

                                            </Col>

                                            <Col sm={12} md={6} lg={4}>
                                                <StatisticsCard stat={{
                                                    title: 'Recompra en Proceso',
                                                    titleClassName: 'text-warning fw-bold',
                                                    value: dailyStatistics.RecompraEnProceso,
                                                    decimal: false,
                                                    suffix: '',
                                                    prefix: '',
                                                    valueClassName: 'text-warning',
                                                    badgeBg: 'warning',


                                                    badgeText: new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date().toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? 'Hoy' : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) + ' - ' + new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }),

                                                    imgLic: iconRecompraProceso,
                                                    image: bgWave,
                                                    className: "mt-1"
                                                }} style={{ minWidth: '12rem' }} />

                                            </Col>

                                            <Col sm={12} md={6} lg={4}>
                                                <StatisticsCard stat={{
                                                    title: 'Recompra Satisfactoria',
                                                    titleClassName: 'text-primary fw-bold',
                                                    value: dailyStatistics.RecompraSatisfactoria ,
                                                    decimal: false,
                                                    suffix: ' vs ' + dailyStatistics.OrdenesRecompra + ' órdenes',
                                                    prefix: '',
                                                    valueClassName: 'text-primary',
                                                    badgeBg: 'primary',


                                                    badgeText: new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date().toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? 'Hoy' : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) == new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) ? new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) : new Date(startDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }) + ' - ' + new Date(endDateStt).toLocaleDateString('es-MX', {
                                                        year: 'numeric', month: 'short', day: '2-digit', hour12: false
                                                    }),

                                                    imgLic: iconCLientesGanados,
                                                    image: bgClienteGanado,
                                                    className: "mt-1"
                                                }} style={{ minWidth: '12rem' }} />

                                            </Col>
                                        </Row>
                                       
                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                    
                </>
            }
        </>
    
  );
};

DailyStatistics.propTypes = {
    /**Mensajes de errores & informativos*/
    vendedor: PropTypes.string.isRequired,
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    /** */
};

export default DailyStatistics;
