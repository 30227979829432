import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import coverSrc from 'assets/img/generic/4.jpg';
import avatar from 'assets/img/team/2.jpg';
import Flex from 'components/common/Flex';
import VerifiedBadge from 'components/common/VerifiedBadge';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProfileBanner from './ProfileBanner';



const Banner = ({ perfil, setShowModalFoto }) => {

    return (
        <ProfileBanner>
            <ProfileBanner.Header avatar={avatar} coverSrc={coverSrc} />
            <ProfileBanner.Body>
                <Row>
                    <Col lg={8}>
                        <Button
                            type="file"
                            variant="primary"
                            className="mb-2"
                            onClick={() => setShowModalFoto(true)}
                        >
                            <FontAwesomeIcon
                                icon="edit"
                                className="me-2"
                            />Cambiar foto</Button>
                        <h5 className="mb-1">
                            @{perfil.vUsuario}
                        </h5>
                        <h4 className="mb-1">
                            {perfil.vNombre} {perfil.vApellidos} <VerifiedBadge />
                        </h4>
                        <h5 className="fs-0 fw-normal">
                            {perfil.vNombreTipoUsuario}
                        </h5>
                        <p className="text-500 mb-0">{perfil.vDomicilioPersonal}</p>
                        <h5 className="fs-0 fw-normal">
                            <img src="/img/whatsapp40.png" width={25}></img>{perfil.vDatosContactoPersonal}
                        </h5>
                    </Col>
                    <Col className="ps-2 ps-lg-3">
                        <Link to="#!">
                            <Flex alignItems="center" className="mb-2">
                                <FontAwesomeIcon
                                    icon="user-circle"
                                    className="me-2 text-700"
                                    style={{ height: '30px', width: '30px' }}
                                />
                                <div className="flex-1">
                                    <h6 className="mb-0">{perfil.vCURP}</h6>
                                </div>
                            </Flex>
                        </Link>
                        <Link to="#!">
                            <Flex alignItems="center" className="mb-2">
                                <img
                                    src="/img/cumple48.png"
                                    width={30}
                                    className="me-2"
                                />
                                <div className="flex-1">
                                    <h6 className="mb-0">{perfil.dFechaNacimiento}</h6>
                                </div>
                            </Flex>
                        </Link>
                        <Link to="#!">
                            <Flex alignItems="center" className="mb-2">
                                <img
                                    src="/img/telefono48.png"
                                    width={30}
                                    className="me-2"
                                />
                                <div className="flex-1">
                                    <h6 className="mb-0">{perfil.vTelefonoTrabajo}</h6>
                                </div>
                            </Flex>
                        </Link>
                        <Link to="#!">
                            <Flex alignItems="center" className="mb-2">
                                <img
                                    src="/img/email48.png"
                                    width={30}
                                    className="me-2"
                                />
                                <div className="flex-1">
                                    <h6 className="mb-0">{perfil.vEmailTrabajo}</h6>
                                </div>
                            </Flex>
                        </Link>
                    </Col>
                </Row>
            </ProfileBanner.Body>
        </ProfileBanner >
    );
};

Banner.propTypes = {
    perfil: PropTypes.object,
    setShowModalFoto: PropTypes.func
};

export default Banner;
