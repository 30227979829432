import PropTypes from 'prop-types';
import React from 'react';

const Banderas = ({ vPais }) => {
    return (
        vPais == "ARGENTINA" ?
            <img src="/img/bandera-argentina-48.png" alt="connectCircle" height={38} className="mb-2" /> :
            vPais == "BELIZE" ?
                <img src="/img/bandera-belize-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                vPais == "BOLIVIA" ?
                    <img src="/img/bandera-bolivia-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                    vPais == "BRASIL" ?
                        <img src="/img/bandera-brasil-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                        vPais == "CANADA" ?
                            <img src="/img/bandera-canada-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                            vPais == "CHILE" ?
                                <img src="/img/bandera-chile-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                vPais == "COLOMBIA" ?
                                    <img src="/img/bandera-colombia-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                    vPais == "COSTA RICA" ?
                                        <img src="/img/bandera-costa-rica-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                        vPais == "CUBA" ?
                                            <img src="/img/bandera-cuba-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                            vPais == "REP\u00DABLICA DOMINICANA" ?
                                                <img src="/img/bandera-dominican-republic-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                vPais == "ECUADOR" ?
                                                    <img src="/img/bandera-ecuador-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                    vPais == "EL SALVADOR" ?
                                                        <img src="/img/bandera-el-salvador-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                        vPais == "GUATEMALA" ?
                                                            <img src="/img/bandera-guatemala-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                            vPais == "GUYANA" ?
                                                                <img src="/img/bandera-guyana-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                                vPais == "HONDURAS" ?
                                                                    <img src="/img/bandera-honduras-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                                    vPais == "JAMAICA" ?
                                                                        <img src="/img/bandera-jamaica-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                                        vPais == "M\u00C9XICO" ?
                                                                            <img src="/img/bandera-mexico-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                                            vPais == "NICARAGUA" ?
                                                                                <img src="/img/bandera-nicaragua-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                                                vPais == "PANAM\u00C1" ?
                                                                                    <img src="/img/bandera-panama-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                                                    vPais == "PARAGUAY" ?
                                                                                        <img src="/img/bandera-paraguay-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                                                        vPais == "PER\u00DA" ?
                                                                                            <img src="/img/bandera-peru-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                                                            vPais == "PUERTO RICO" ?
                                                                                                <img src="/img/bandera-puerto-rico-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                                                                vPais == "ESPA\u00D1A" ?
                                                                                                    <img src="/img/bandera-spain-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                                                                    vPais == "URUGUAY" ?
                                                                                                        <img src="/img/bandera-uruguay-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                                                                        vPais == "ESTADOS UNIDOS" ?
                                                                                                            <img src="/img/bandera-usa-48.png" alt="connectCircle" height={38} className="mb-2" /> :
                                                                                                            vPais == "VENEZUELA" ?
                                                                                                                <img src="/img/bandera-venezuela-48.png" alt="connectCircle" height={38} className="mb-2" /> : ""
    );
};

Banderas.propTypes = {
    vPais: PropTypes.string
};

export default Banderas;
