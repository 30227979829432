
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
/*
import classNames from 'classnames';
import PropTypes from 'prop-types';
*/
import { Col, Row } from 'react-bootstrap';
/*

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TitleComponent,
    ToolboxComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
*/

//import { CanvasRenderer } from 'echarts/renderers';

import MensajeErrror from 'components/common/MensajeError';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';


import Greetings from './Greeting/Greetings.js';
import TotalAnualByMonth from './TotalAnualByMonth.js';
import VentasPorVendedor from './VentasPorVendedor.js';
import DailyLeads from './leads/DailyLeads.js';
import LeadsByCountry from './leads-by-country/LeadsByCountry.js';
import LeadsPorEtapa from './leads-by-etapa/LeadsPorEtapa.js';
import LeadsActualizados from './leads-actualizados/LeadsActualizados.js';


/*
echarts.use([
    GridComponent,
    ToolboxComponent,
    TitleComponent,
    LineChart,
    CanvasRenderer
]);*/
/*
const FormCheck = ({ title, id, inputClass, checkBoxClass, handleLegend, color }) => {
    return (
        <Form.Check
            className={classNames(checkBoxClass, 'd-flex align-items-center mb-0')}
        >
            <Form.Check.Input
                onChange={event => handleLegend(event, title)}
                type="checkbox"
                id={id}
                defaultChecked
                className={classNames(
                    inputClass,
                    'dot mt-0 shadow-none remove-checked-icon rounded-circle cursor-pointer'
                )}
            />
            <Form.Check.Label
                htmlFor={id}
                className="form-check-label lh-base mb-0 fs--2 text-500 fw-semi-bold font-base cursor-pointer"

            >
                <span style={{ color: color }} className="fw-bold">{title}</span>
            </Form.Check.Label>
        </Form.Check>
    );
};

FormCheck.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    inputClass: PropTypes.string,
    checkBoxClass: PropTypes.string,
    handleLegend: PropTypes.func,
    color: PropTypes.string,
};
*/
/*
const TiposLicencias = ({ title, number, percentage, icon, badgeColor }) => {
    return (
        <div>
            <h6 className="fs-0 d-flex align-items-center text-700 mb-1">
                {number}
                <Badge pill bg="transparent" className={classNames(badgeColor, 'px-0')}>
                    <FontAwesomeIcon icon={icon} className="fs--2 ms-2 me-1" />
                    {percentage}
                </Badge>
            </h6>
            <h6 className="fs--2 text-600 mb-0 text-nowrap">{title}</h6>
        </div>
    );
};

TiposLicencias.propTypes = {
    title: PropTypes.string.isRequired,
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    percentage: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    badgeColor: PropTypes.string
};
*/

const AdministradoresEstadisticas = () => {

    const { token, idCatTipoUsuario } = useToken();
    const navigate = useNavigate();

    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);

    /**
     * Se ejecuta la primera vez
     */
    useEffect(() => {

        if (idCatTipoUsuario != 1) {
            navigate("/");
        }

        checkPermisos();

    }, []);

    /**
     * Verifica los permisos
     */
    const checkPermisos = async () => {

        const response = await fetch("/api/Administradores/checkPermisos",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {

               //
                

            } else {
                navigate("/");
            }

        }
        else {
            navigate("/");
        }
    }



    return (
        <>
            <Row className="">

                <Col className="col-12 mb-3">
                    <Greetings/>
                </Col>

                <Col className="col-12 mb-3">
                    <TotalAnualByMonth
                        setMensajeError={setMensajeError}
                        setShowMensajeError={setShowMensajeError}
                    />
                </Col>

                <Col className="col-12 mb-3">
                    <VentasPorVendedor
                        setMensajeError={setMensajeError}
                        setShowMensajeError={setShowMensajeError}
                        items_Money={"MONEY"}
                    />
                </Col>

                <Col className="col-12 mb-3">
                    <LeadsPorEtapa
                        setMensajeError={setMensajeError}
                        setShowMensajeError={setShowMensajeError}
                    />
                </Col>

                <Col className="col-12 mb-3">
                    <LeadsActualizados
                        setMensajeError={setMensajeError}
                        setShowMensajeError={setShowMensajeError}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="col-lg-4 col-md-6 col-sm-12 mb-3">
                    <LeadsByCountry
                        setMensajeError={setMensajeError}
                        setShowMensajeError={setShowMensajeError}
                    />                    
                </Col>
                <Col className="col-lg-8 col-md-6 col-sm-12 mb-3">
                    <DailyLeads
                        setMensajeError={setMensajeError}
                        setShowMensajeError={setShowMensajeError}
                    />
                </Col>
            </Row>

            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <ToastContainer/>
        </>
    );
};

export default AdministradoresEstadisticas;
