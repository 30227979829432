
import classNames from 'classnames';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';

import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import DatePicker from "react-datepicker";

import { Link, useNavigate } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer } from 'react-toastify';

import ModalConciliarPago from './ModalConciliarPago';


const Pagos = () => {

    //Columnas
    const columns = [
        {
            accessor: 'none1',
            Header: '',
            Cell: rowData => {
                const { idPago } = rowData.row.original;
                return (
                    <Link onClick={() => { setID_Pago(idPago); setShowModalConciliar(true) }}>
                        <img title="Ver informaci&oacute;n" src="/img/ver20.png" ></img>
                    </Link>
                );
            }
        },
        {
            accessor: 'idPago',
            Header: 'ID',
            Cell: rowData => {
                const { idPago } = rowData.row.original;
                return (
                    <>
                        <Link onClick={() => { setID_Pago(idPago); setShowModalConciliar(true) }}>
                            <strong>{idPago}</strong>
                        </Link>
                    </>

                );
            }
        },
        {
            accessor: 'none',
            Header: '',
            Cell: rowData => {
                const { bIsActive, bIsFacturado, idCatCuentaBancaria } = rowData.row.original;
                return (
                    <>
                        <Row className="justify-content-center">
                            <Col xs="auto" >


                            </Col>

                            <Col xs="auto" className="mb-1 mt-1 fs--1 col-10">
                                <img title={bIsActive == 1 ? "Activo" : "No Activo"} src={bIsActive == 1 ? "/img/activo.png" : "/img/noactivo.png"}></img>
                                <img title={idCatCuentaBancaria > 0 ? "Validado" : "No Validado"} src={idCatCuentaBancaria > 0 ? "/img/activo.png" : "/img/noactivo.png"}></img>
                                <img title={bIsFacturado == 1 ? "Conciliado" : "No Conciliado"} src={bIsFacturado == 1 ? "/img/activo.png" : "/img/noactivo.png"}></img>
                            </Col>
                        </Row>
                    </>
                );
            }
        },
        {
            accessor: 'idOrden',
            Header: 'Orden',
            width: "fit-content",
            cellProps: {
                className: 'py-3'
            },
            Cell: rowData => {
                const { idOrden, vNombreCliente, bIsActive } = rowData.row.original;
                return (
                    <>
                        <span>
                            <Link to={"/ordenes/detalle/" + idOrden}>
                                <strong className={classNames({
                                    "text-danger": bIsActive == false,
                                })}># {idOrden}</strong>
                            </Link>{'   '}&nbsp;&nbsp;&nbsp;
                            <Link to={"/ordenes/detalle/" + idOrden}>
                                <strong className={classNames({
                                    "text-danger": bIsActive == false,
                                })}>{vNombreCliente}</strong> <br />
                            </Link>
                        </span>
                    </>
                );
            }
        },
        {
            accessor: 'dFechaPagoLargo',
            Header: 'Fecha Pago',
            Cell: rowData => {
                const { dFechaPagoLargo } = rowData.row.original;
                return (
                    <>
                        <span>{dFechaPagoLargo}</span>


                    </>
                );
            }
        },
        {
            accessor: 'vUsuario',
            Header: 'Vendedor',
            Cell: rowData => {
                const { vUsuario } = rowData.row.original;
                return (
                    <span> {vUsuario}</span>
                );
            }
        },

        {
            accessor: 'dImportePago',
            Header: 'Importe Pago',
            Cell: rowData => {
                const { dImportePago } = rowData.row.original;
                return (
                    <>
                        <span className="text-end fs--1 fw-medium py-2">${parseFloat(dImportePago).toLocaleString("es-MX", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        })}</span>
                    </>
                );
            }
        },
        {
            accessor: 'vMoneda',
            Header: 'Moneda',
            Cell: rowData => {
                const { vMoneda, dTipoCambio } = rowData.row.original;
                return (
                    <>
                        <span>{vMoneda} {vMoneda == "MXN" ? "" : "T.C. " + parseFloat(dTipoCambio).toFixed(4)} </span>
                    </>
                );
            }
        },
        {
            accessor: 'vReferencia',
            Header: 'Referencia',
            Cell: rowData => {
                const { vReferencia } = rowData.row.original;
                return (
                    <>
                        <span>{vReferencia}  </span>
                    </>
                );
            }
        },
        {
            accessor: 'vMedioPago',
            Header: 'Medio de Pago',
            Cell: rowData => {
                const { vMedioPago } = rowData.row.original;
                return (
                    <>
                        {vMedioPago == 'EFECTIVO' && <img src="/img/efectivo20.png"></img>}
                        {vMedioPago == 'STRIPE' && <img src="/img/stripe30.png"></img>}
                        {vMedioPago == 'PAYPAL' && <img src="/img/paypal20.png"></img>}
                        {vMedioPago == 'BANCO' && <img src="/img/transferencia20.png"></img>}
                        <span>{vMedioPago} </span>
                    </>
                );
            }
        },
        {
            accessor: 'vDescripcionFormaPago',
            Header: 'Forma de Pago',
            Cell: rowData => {
                const { vClave, vDescripcionFormaPago } = rowData.row.original;
                return (
                    <>
                        <span className="d-inline-block text-truncate" style={{ maxWidth: '150px' }}>
                            {vClave == '01' && <img src="/img/efectivo20.png"></img>}
                            {vClave == '03' && <img src="/img/transferencia20.png"></img>}
                            {vClave == '99' && <img src="/img/pordefinir20.png"></img>}
                            {vClave == '28' && <img src="/img/tarjeta20.png"></img>}
                            {vClave == '04' && <img src="/img/tarjeta20.png"></img>}
                            {vClave == '31' && <img src="/img/intermediario20.png"></img>}
                            {vClave} - {vDescripcionFormaPago}
                        </span>
                    </>
                );
            }
        }

    ];

    //AUTENTICACION
    const { token, setToken, idCatTipoUsuario } = useToken();

    //datos
    // const {  formState: { errors } } = useForm();
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);

    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);

    const [pagos, setPagos] = useState([]);
    const [subtotalUSD, setSubtotalUSD] = useState('');
    const [subtotalMXN, setSubtotalMXN] = useState('');
    const [totalMXN, setTotalMXN] = useState('');

    const [alertasPagos, setAlertasPagos] = useState({});
    const [showModalConciliar, setShowModalConciliar] = useState(false);
    const [vendedores, setVendedores] = useState([]);
    const [cuentas, setCuentas] = useState([]);
    const [mediosPago, setMediosPago] = useState([]);
    const [ID_Pago, setID_Pago] = useState(0);

    /**fILTERS */
    const [dateQuarter, setDateQuarter] = useState(new Date())
    const [idCatCuentaBancaria, setIdCatCuentaBancaria] = useState('');
    const [idCatMedioPago, setIdCatMedioPago] = useState('');
    const [idVendedor, setIDVendedor] = useState('');
    const [filterValidados, setFilterValidados] = useState("-1");
    const [filterStatus, setFilterStatus] = useState("-1");
    const [cargando, setCargando] = useState(true);
    const [show, setShow] = useState(true);
    const [showAlertas, setShowAlertas] = useState(true);
    const [CargandoGetRefDuplicadas, setCargandoGetRefDuplicadas] = useState(true);
    const [referenciasDuplicadas, setReferenciasDuplicadas] = useState([]);
    const [pagosRefDupli, setPagosRefDupli] = useState([]);
    const navigate = useNavigate();

    /**
     * Se ejecuta la primera vez
     */
    useEffect(() => {

        if (idCatTipoUsuario != 1) {
            navigate("/");
        }

        checkPermisos();


    }, [filterStatus, filterValidados]);


    /**
    * Verifica los permisos
    */
    const checkPermisos = async () => {

        const response = await fetch("/api/Administradores/checkPermisos",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {

                ObtenerPagos();

            } else {
                navigate("/");
            }

        }
        else {
            navigate("/");
        }
    }


    /**
     * Obtiene los pagos
     * @param {any} date_
     * @param {any} idCatCuentaBancaria
     */
    const ObtenerPagos = async () => {

        setPagos([]);
        setCargando(true);
        setCargandoGetRefDuplicadas(true);

        var date_ = sessionStorage.getItem("selectMes") == null ? dateQuarter : sessionStorage.getItem("selectMes");
        var idCatCuentaBancaria = sessionStorage.getItem("ctaBancariaConciliacion") == null ? '' : sessionStorage.getItem("ctaBancariaConciliacion");
        var idMedioPago = sessionStorage.getItem("idMedioPago") == null ? '' : sessionStorage.getItem("idMedioPago");
        var idVendedor_ = sessionStorage.getItem("selectIDUsuario") == null ? '' : sessionStorage.getItem("selectIDUsuario");

        setDateQuarter(new Date(date_));
        setIdCatCuentaBancaria(idCatCuentaBancaria);
        setIdCatMedioPago(idMedioPago);
        setIDVendedor(idVendedor_);

        var iValidados_ = filterValidados;
        var iStatus_ = filterStatus;

        var month = new Date(date_).getMonth();
        month = month + 1;
        var year = new Date(date_).getFullYear();

        const response = await fetch(
            "/api/conciliacion/ObtenerPagos/?month=" + month + "&year=" + year +
            "&idCatCuentaBancaria=" + idCatCuentaBancaria + "&idMedioPago=" + idMedioPago +
            "&idVendedor=" + idVendedor_ + "&iValidado=" + iValidados_ + "&iEstatus=" + iStatus_,
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
        )
            .catch((error) => {

                setMensajeError('' + error.message);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            const objResponse = await response.json();
            console.log(objResponse);

            if (objResponse.iResponseType == 1) {

                const data = JSON.parse(objResponse.detalles);
                console.log(data);
                setPagos(data['table2']);
                setSubtotalUSD(data['table3'][0].SubtotalUSD);
                setSubtotalMXN(data['table4'][0].SubtotalMXN);
                setTotalMXN(data['table5'][0].totalMXN);
                setAlertasPagos(data['table6'][0]);
                setVendedores(data['table7']);
                setMediosPago(data['table8']);
                setCuentas(data['table9']);
                ObtenerAlertaReferencias();

            } else {
                navigate("/");
            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);

    };



    /**
     * Obtiene referencias Repetidas
     */
    const ObtenerAlertaReferencias = async () => {

        setCargandoGetRefDuplicadas(true);

        const response = await fetch("/api/conciliacion/obtenerReferenciasDuplicadas/", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {

                setMensajeError('' + error.message);
                setShowMensajeError(true);

                console.log('Hubo un problema con la peticion Fetch:' + error.message);
            });

        if (response.ok) {
            const responseType = await response.json();
            console.log(responseType);

            if (responseType.iResponseType == 1) {

                const data = JSON.parse(responseType.detalles);

                setReferenciasDuplicadas(data);

                console.log("******************* PAGOS REF DUPLICADAS *****************");

                //console.log(data);
                //console.log(data.length);
                if (data.length > 0) {
                    const dataIDPagos = JSON.parse(data[0].Pagos);
                    console.log(dataIDPagos);
                    setPagosRefDupli(dataIDPagos);
                }
                //const dataIDPagos = JSON.parse(data[6].Pagos);
                //console.log(data[6].Pagos);

                console.log("******************* PAGOS REF DUPLICADAS *****************");

            } else {

                setMensajeError(responseType.vMessage);
                setShowMensajeError(true);

            }

            setCargandoGetRefDuplicadas(false);
        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }

            console.log(response);
            setCargandoGetRefDuplicadas(false);

        }

    };



    return (
        <>
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                        <img src="/img/ordenes96.png" alt="connectCircle" height={30} />
                    </Col>
                    <Col>
                        <h5>
                            Conciliaci&oacute;n de pagos
                        </h5>
                    </Col>

                    <Col sm="auto" className="ms-auto">

                    </Col>
                </Row>
            </Card>

            <Card className="mb-1">

                <Card.Header>
                    <Row className="justify-content-center">

                        <Col className="col-xxl-4 col-xl-5 col-lg-6 col-md-7 col-sm-12 col-12 align-items-center d-flex mb-2">

                            <div className="d-flex align-items-center">
                                {
                                    cargando ?
                                        <Skeleton height={50} width={100} />
                                        :
                                        <div>
                                            <h6 className="fs--1 text-600 mb-0 text-nowrap">Subtotal MXN</h6>
                                            <h6 className="fs-0 d-flex align-items-center text-700 mb-1">
                                                {subtotalMXN.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                                            </h6>
                                            <h6 className="fs--2 text-600 mb-0 mt-0">IVA {(subtotalMXN - (subtotalMXN / 1.16)).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</h6>

                                        </div>
                                }

                                <div className="bg-200 mx-3" style={{ height: '24px', width: '1px' }}></div>
                            </div>
                            <div className="d-flex align-items-center">
                                {
                                    cargando ?
                                        <Skeleton height={50} width={100} />
                                        :
                                        <div>
                                            <h6 className="fs--1 text-600 mb-0 text-nowrap">Subtotal USD</h6>
                                            <h6 className="fs-0 d-flex align-items-center text-700 mb-1">
                                                {subtotalUSD.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                            </h6>
                                            <h6 className="fs--2 text-600 mb-0 mt-0">IVA {(subtotalUSD - (subtotalUSD / 1.16)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h6>

                                        </div>
                                }

                                <div className="bg-200 mx-3" style={{ height: '24px', width: '1px' }}></div>
                            </div>
                            <div className="d-flex align-items-center">
                                {
                                    cargando ?
                                        <Skeleton height={50} width={100} />
                                        :
                                        <div>

                                            <h6 className="fs--1 text-600 mb-0 text-nowrap">TOTAL MXN</h6>
                                            <h6 className="fs-0 d-flex align-items-center text-primary mb-1">
                                                {totalMXN.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                                            </h6>
                                            <h6 className="fs--2 text-600 mb-0 mt-0">IVA {(totalMXN - (totalMXN / 1.16)).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</h6>
                                        </div>
                                }

                                <div className="bg-200 mx-3" style={{ height: '24px', width: '1px' }}></div>
                            </div>

                        </Col>

                        <Col>

                            {
                                show ?
                                    <Alert key={"warning"} variant={"warning"} onClose={() => setShow(false)} className="fs--1" dismissible>
                                        🏴󠁢󠁳󠁢󠁰󠁿 Los <b>totales</b> estan basados en todos los pagos <b>ACTIVOS</b> registrados.
                                        <br />
                                        🏴󠁢󠁳󠁢󠁰󠁿 Los <b>totales</b> solo son el resultado de los filtros seleccionados.
                                    </Alert>
                                    :
                                    <span></span>
                            }


                        </Col>

                    </Row>

                    <Row>

                        {
                            cargando ?
                                <Skeleton height={150} className="mb-2" />
                                :
                                <Col className="col-12">
                                    {
                                        showAlertas && (alertasPagos.numNoValidados > 0 || alertasPagos.numNoFacturados > 0 || alertasPagos.numNoActivos > 0) &&
                                        <Alert key={"warning"} variant={"warning"} onClose={() => setShowAlertas(false)} className="fs--1" dismissible>
                                            Las <b>alertas</b> a continuación, son de <b>acuerdo</b> a los <b>datos</b> devueltos con los <b>filtros configurados</b>.
                                            <br /><br />

                                            {alertasPagos.numNoValidados > 0 &&
                                                <p className="mb-1">
                                                    🏴󠁢󠁳󠁢󠁰󠁿 <b>{alertasPagos.numNoValidados}</b> <strong> {alertasPagos.numNoValidados > 1 ? "pagos" : "pago"}</strong> <span className="text-danger fw-bold">{alertasPagos.numNoValidados > 1 ? "NO validados" : "NO validado"}</span>.
                                                </p>

                                            }

                                            {alertasPagos.numNoFacturados > 0 &&
                                                <p className="mb-1 mt-1">
                                                    🏴󠁢󠁳󠁢󠁰󠁿 <b>{alertasPagos.numNoFacturados}</b> <strong>{alertasPagos.numNoFacturados > 1 ? "pagos" : "pago"}</strong> <span className="text-danger fw-bold">{alertasPagos.numNoFacturados > 1 ? "NO conciliados" : "NO conciliado"}</span>.
                                                </p>

                                            }

                                            {alertasPagos.numNoActivos > 0 &&
                                                <p className="mb-1 mt-1">
                                                    🏴󠁢󠁳󠁢󠁰󠁿 <b>{alertasPagos.numNoActivos}</b> <strong>{alertasPagos.numNoActivos > 1 ? "pagos" : "pago"}</strong> <span className="text-danger fw-bold">{alertasPagos.numNoActivos > 1 ? "Cancelados" : "Cancelado"}</span>.
                                                </p>

                                            }



                                        </Alert>
                                    }

                                </Col>
                        }


                        {
                            CargandoGetRefDuplicadas ?
                                <>
                                    <Skeleton height={50} />
                                    <Skeleton height={50} />
                                    <Skeleton height={50} />
                                </>
                                :
                                <>
                                    {
                                        referenciasDuplicadas.length > 0 && <>
                                            <Col className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                                                {
                                                    <Alert key={"danger"} variant={"danger"} className="fs--1" >
                                                        <p><strong>Referencias repetidas:</strong></p>
                                                        <Row>
                                                            {

                                                                referenciasDuplicadas.map((ref, i) => {
                                                                    return <Col key={i} className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" onClick={() => { setPagosRefDupli(JSON.parse(ref.Pagos)); }} >
                                                                        <Link >
                                                                            <span className="text-danger" >{"[" + ref.cantRef + "] "}  <strong>{ref.vReferencia}</strong>  </span>

                                                                        </Link>
                                                                    </Col>
                                                                })
                                                            }
                                                        </Row>

                                                    </Alert>

                                                }

                                            </Col>
                                            <Col>
                                                {pagosRefDupli.length > 0 ?
                                                    <span>
                                                        <span className="fs--1">
                                                            Pagos bajo la referencia <strong className="text-danger">{pagosRefDupli[0].vReferencia}</strong>
                                                        </span>

                                                        {
                                                            pagosRefDupli.map((pago, i) => {
                                                                return (
                                                                    <Button
                                                                        key={i}
                                                                        variant={pago.bIsActive == 1 ? "info" : "danger"}
                                                                        size="sm"
                                                                        className="fs--2 m-1"
                                                                        onClick={() => { setID_Pago(pago.idPago); setShowModalConciliar(true) }}
                                                                    > Pago #{pago.idPago}🧾
                                                                    </Button>
                                                                )
                                                            })
                                                        }
                                                    </span>
                                                    :
                                                    <></>

                                                }
                                            </Col>
                                        </>


                                    }

                                </>

                        }



                    </Row>
                </Card.Header>

            </Card>

            <AdvanceTableWrapper
                columns={columns}
                data={pagos}
                sortable
                pagination
                perPage={15}
            >
                <Card className="mb-3">
                    <Card.Header>


                        <Row className="mt-2">
                            <Col className="col mb-1"  >
                                {cargando ? <Skeleton /> : <span><Form.Label className="text-700">Mes</Form.Label> <br /></span>}

                                {cargando ? <Skeleton /> :
                                    <DatePicker
                                        selected={dateQuarter}
                                        onChange={(date) => {
                                            console.log(date);
                                            sessionStorage.setItem("selectMes", date);
                                            //setDateQuarter(new Date(date));
                                            ObtenerPagos(/*date, idCatCuentaBancaria, idCatMedioPago*/);
                                        }}
                                        dateFormat="MMMM yyyy - QQQ"
                                        className="form-control w-100"
                                        showMonthYearPicker
                                    />
                                }

                            </Col>

                            <Col className="col mb-1">
                                {cargando ? <Skeleton /> : <Form.Label className="text-700">Vendedores</Form.Label>}

                                {cargando ? <Skeleton /> :
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        className="form-control"
                                        onChange={(e) => {
                                            setIDVendedor(e.target.value);
                                            sessionStorage.setItem("selectIDUsuario", e.target.value);
                                            ObtenerPagos();
                                        }}
                                        value={idVendedor}
                                    >
                                        <option value="">Todos los vendedores</option>
                                        {vendedores.map((vendedor, i) => {
                                            return <option key={i} value={vendedor.idUsuario}>{vendedor.vNombre + ' ' + vendedor.vApellidos}</option>
                                        })}
                                    </Form.Select>
                                }
                            </Col>

                            <Col className="col  mb-1">
                                {cargando ? <Skeleton /> : <Form.Label className="text-700">Cuentas</Form.Label>}

                                {cargando ? <Skeleton /> :
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        className="form-control"
                                        onChange={(e) => {
                                            setIdCatCuentaBancaria(e.target.value);
                                            sessionStorage.setItem("ctaBancariaConciliacion", e.target.value);
                                            ObtenerPagos(/*dateQuarter, e.target.value*/);
                                        }}
                                        value={idCatCuentaBancaria}
                                    >
                                        <option value="">Todas las cuentas</option>
                                        {cuentas.map((cta, i) => {
                                            return <option key={i} value={cta.idCatCuentaBancaria}>{cta.vNumCta}-{cta.vAlias}</option>
                                        })}
                                    </Form.Select>
                                }
                            </Col>

                            <Col className="col mb-1">
                                {cargando ? <Skeleton /> : <Form.Label className="text-700">Medio de Pagos</Form.Label>}

                                {cargando ? <Skeleton /> :
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select"
                                        onChange={(e) => {
                                            setIdCatMedioPago(e.target.value);
                                            sessionStorage.setItem("idMedioPago", e.target.value);
                                            ObtenerPagos(/*dateQuarter, idCatCuentaBancaria, e.target.value*/);
                                        }}
                                        value={idCatMedioPago}
                                    >
                                        <option value="">Todos los medios de Pago</option>
                                        {mediosPago.map((medioPago, i) => {
                                            return <option key={i} value={medioPago.idCatMedioPago}>{medioPago.idCatMedioPago}-{medioPago.vMedioPago}</option>
                                        })}
                                    </Form.Select>
                                }
                            </Col>

                        </Row>

                        <Row className="mt-1">

                            <Col className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6">
                                <Form>
                                    {cargando ? <Skeleton /> : <Form.Label className="text-700 fs--2">Validados</Form.Label>}

                                    {cargando ? <Skeleton /> :
                                        <Form.Select
                                            size="sm"
                                            aria-label="Default select"
                                            className="form-control"
                                            onChange={(e) => {
                                                setFilterValidados(e.target.value);
                                                //setIDVendedor(e.target.value);
                                                //sessionStorage.setItem("selectIDUsuario", e.target.value);
                                                //ObtenerPagos();
                                            }}
                                            value={filterValidados}
                                        >
                                            <option value="-1">TODOS</option>
                                            <option value="1">Validados</option>
                                            <option value="0">No Validados</option>
                                        </Form.Select>
                                    }
                                </Form>



                            </Col>

                            <Col className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6">
                                <Form>
                                    {cargando ? <Skeleton /> : <Form.Label className="text-700 fs--2">Estatus</Form.Label>}
                                    {cargando ? <Skeleton /> :
                                        <Form.Select
                                            size="sm"
                                            aria-label="Default select"
                                            className="form-control"
                                            onChange={(e) => {
                                                setFilterStatus(e.target.value);
                                                //setIDVendedor(e.target.value);
                                                //sessionStorage.setItem("selectIDUsuario", e.target.value);
                                                //ObtenerPagos();
                                            }}
                                            value={filterStatus}
                                        >
                                            <option value="-1">TODOS</option>
                                            <option value="1">Activos</option>
                                            <option value="0">Cancelados</option>
                                        </Form.Select>
                                    }
                                </Form>



                            </Col>

                        </Row>
                        <Col className="ms-auto mt-2 align-items-end col-xxl-3 coxl-3 col-lg-4 col-md-6 col-sm-12 col-12" >
                            {!cargando &&
                                <AdvanceTableSearchBox table />
                            }
                        </Col>
                        <Row>

                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            rowCount={pagos.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            {cargando && (<h5><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h5>)}

            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
            <ToastContainer />
            {showModalConciliar &&
                <ModalConciliarPago
                showModalConciliar={showModalConciliar}
                setShowModalConciliar={setShowModalConciliar}

                setShowMensajeError={setShowMensajeError}
                setMensajeError={setMensajeError}

                mensajeInformativo={mensajeInformativo}
                setMensajeInformativo={setMensajeInformativo}
                showMensajeInformativo={showMensajeInformativo}
                setShowMensajeInformativo={setShowMensajeInformativo}

                idPago={ID_Pago}
                />
            }
        </>
    );
};

export default Pagos;
