import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button, CloseButton } from 'react-bootstrap';

const ModalDatosFiscalesIncompletos = ({
    showModalDatosFiscalesIncompletos,
    setShowModalDatosFiscalesIncompletos
}) => {

    return (
        <>
            <Modal
                show={showModalDatosFiscalesIncompletos}
                onHide={() => setShowModalDatosFiscalesIncompletos(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <img width="25" src="/img/advertencia20.png"></img>
                    <Modal.Title id="contained-modal-title-vcenter">Datos fiscales incompletos</Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => setShowModalDatosFiscalesIncompletos(false)}
                    />
                </Modal.Header>
                <Modal.Body>
                    <h5>Antes de crear una orden, aseg&uacute;rate de completar los datos fiscales del cliente</h5>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant='success' onClick={() => { setShowModalDatosFiscalesIncompletos(false); }}>Aceptar</Button>

                </Modal.Footer>
            </Modal>
        </>
    )
    
}

ModalDatosFiscalesIncompletos.propTypes = {
    showModalDatosFiscalesIncompletos : PropTypes.bool,
    setShowModalDatosFiscalesIncompletos : PropTypes.func
}

export default ModalDatosFiscalesIncompletos;