import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import MensajeErrror from 'components/common/MensajeError';
import { ToastContainer } from 'react-toastify';
import SoftBadge from 'components/common/SoftBadge';
import useFiltro from 'hooks/useFiltro';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const columns = [
    {
        accessor: 'none',
        Header: '',
        Cell: rowData => {
            const { idLicencia } = rowData.row.original;
            return (
                <>
                    <Row className="flex-end-center">
                        <Col xs="auto" >
                            <Link to={"/licencias/detalle/" + idLicencia}>
                                <img title="Ver informaci&oacute;n" src="../img/ver20.png"></img >
                            </Link>
                        </Col>
                    </Row>
                </>
            );
        }
    },
    {
        accessor: 'dCreadtedDate',
        Header: 'Fecha movimiento',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { dCreatedDate } = rowData.row.original;
            return (<span>{dCreatedDate}</span>)
        }
    },
    {
        accessor: 'idLicencia',
        Header: 'Id Lic',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { idLicencia, vUUIDEmpresa, vUUIDLicencia, bIsPeriodoPrueba } = rowData.row.original;
            return (
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip style={{ position: 'fixed' }} >
                            <strong>UUID EMPRESA</strong>
                            <p>{vUUIDEmpresa}</p>
                            <strong>UUID LICENCIA</strong>
                            <p>{vUUIDLicencia}</p>
                        </Tooltip>
                    }
                >
                    {bIsPeriodoPrueba ?
                        <span className="text-danger">
                            <Link to={"/licencias/detalle/" + idLicencia}> <b>{idLicencia}</b></Link>
                        </span>
                        :
                        <span>
                            <Link to={"/licencias/detalle/" + idLicencia}> <b>{idLicencia}</b></Link>
                        </span>}
                </OverlayTrigger>
            )
        }
    },
    {
        accessor: 'vNombreVendedor',
        Header: 'Vendedor',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vNombreVendedor, bIsPeriodoPrueba } = rowData.row.original;
            return bIsPeriodoPrueba ? (<span className="text-danger text-truncate" style={{ maxWidth: '250px' }}>{vNombreVendedor}</span>) : (<span className="text-truncate" style={{ maxWidth: '250px' }}> {vNombreVendedor}</span>)
        }
    },
    {
        accessor: 'bIsperiodoPrueba',
        Header: 'Estatus',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { bIsPeriodoPrueba, bCancelado, iEstadoPoliza, dFechaVencimientoSoporte, idCatTipoLicencia, dDiasFaltantes, dFinLicenciaUso } = rowData.row.original;

            return (
                bCancelado ? (
                    <>
                        <SoftBadge pill bg='danger' className='me-2'>CANCELADO</SoftBadge>
                    </>
                ) : (
                    <>
                        {bIsPeriodoPrueba ? <img src="../img/noactivo.png"></img > : <img src="../img/activo.png"></img>}
                        {idCatTipoLicencia == 3 ?

                            <OverlayTrigger
                                overlay={
                                    <Tooltip style={{ position: 'fixed' }} >
                                        <strong>NUBE AKASIA</strong><br></br>
                                        <span>Vencimiento: {dFinLicenciaUso}</span>
                                        <br></br>
                                        <span>Restante: {dDiasFaltantes} d&iacute;as</span>
                                    </Tooltip>
                                }
                            >
                                {dDiasFaltantes > 0 ? <img src="../img/nubeActiva.png"></img > : <img src="../img/nubeVencida.png"></img >}
                            </OverlayTrigger>


                            : ''}
                        {iEstadoPoliza == 1 &&
                            <>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip style={{ position: 'fixed' }} >
                                            <strong>P&Oacute;LIZA SOPORTE T&Eacute;CNICO</strong>
                                            <br></br>
                                            <span>Vencimiento: {dFechaVencimientoSoporte}</span>
                                        </Tooltip>
                                    }
                                >
                                    <img src="../img/polizaActiva.png"></img >
                                </OverlayTrigger>
                            </>
                        }
                        {iEstadoPoliza == 2 &&
                            <>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip style={{ position: 'fixed' }} >
                                            <strong>P&Oacute;LIZA SOPORTE T&Eacute;CNICO</strong>
                                            <br></br>
                                            <span>Vencimiento: {dFechaVencimientoSoporte}</span>
                                        </Tooltip>
                                    }
                                >
                                    <img src="../img/polizaVencida.png"></img >
                                </OverlayTrigger>
                            </>
                        }
                        {iEstadoPoliza == 3 &&
                            <>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip style={{ position: 'fixed' }} >
                                            <strong>P&Oacute;LIZA SOPORTE T&Eacute;CNICO</strong>
                                            <br></br>
                                            <span>Vencimiento: {dFechaVencimientoSoporte}</span>
                                        </Tooltip>
                                    }
                                >
                                    <img src="../img/polizaPorVencer.png"></img >
                                </OverlayTrigger>
                            </>
                        }

                    </>
                )
            )
        }
    },
    {
        accessor: 'vVersion',
        Header: 'Version',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vVersion, bIsPeriodoPrueba } = rowData.row.original;
            return bIsPeriodoPrueba ? (<span className="text-danger text-truncate" style={{ maxWidth: '250px' }}>{vVersion}</span>) : (<span className="text-truncate" style={{ maxWidth: '250px' }}> {vVersion}</span>)
        }
    },
    {
        accessor: 'vIP',
        Header: 'IP',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vLatLonSistema, vDatosHardware, vNombreSucursal } = rowData.row.original;
            return (
                <>
                    {vNombreSucursal &&
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip style={{ position: 'fixed' }} >
                                    <strong>{vNombreSucursal}</strong>
                                </Tooltip>
                            }
                        >
                            <img src="/img/sucursal20.png"></img>
                        </OverlayTrigger>}
                    {vDatosHardware &&
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip style={{ position: 'fixed' }} >
                                    <strong>{vDatosHardware}</strong>
                                </Tooltip>
                            }
                        >
                            <img src="/img/pcautorizada20.png"></img>
                        </OverlayTrigger>}
                    {vLatLonSistema &&
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip style={{ position: 'fixed' }} >
                                    <strong>{vLatLonSistema}</strong>
                                </Tooltip>
                            }
                        >
                            <a href={"https://www.google.com/maps/place/" + vLatLonSistema} target="_blank" rel="noreferrer"><img src="/img/ubicacion20.png"></img></a>
                        </OverlayTrigger>}

                </>
            )
        }
    },

    {
        accessor: 'vNombreEmpresa',
        Header: 'Nombre Empresa',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vNombreEmpresa, bIsPeriodoPrueba, idLicencia } = rowData.row.original;
            return (<Link to={"/licencias/detalle/" + idLicencia}>
                <Flex alignItems="center">

                    {bIsPeriodoPrueba ? <span className="text-danger d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vNombreEmpresa}</span> : <span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vNombreEmpresa}</span>}

                </Flex>
            </Link>);
        }
    },
    {
        accessor: 'vMovimiento',
        Header: 'Movimiento',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vMovimiento, bIsPeriodoPrueba } = rowData.row.original;
            return bIsPeriodoPrueba ? (<span className="text-danger d-inline-block text-truncate" style={{ maxWidth: '400px' }}>{vMovimiento}</span>) : (<span className="d-inline-block text-truncate" style={{ maxWidth: '400px' }}>{vMovimiento}</span>)
        }

    },
    {
        accessor: 'vNombreCliente',
        Header: 'Nombre Cliente',
        cellProps: { className: 'ps-1' },
        Cell: rowData => {
            const { vNombreCliente, bIsPeriodoPrueba, idClienteAkasia, vTelefonoPersonal, vTelefonoTrabajo, vEmail } = rowData.row.original;
            return (
                <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip className="fs--2" style={{ position: 'fixed' }} >
                            {vTelefonoPersonal && <><strong>TEL. PERSONAL</strong><br></br>
                                <span>{vTelefonoPersonal}</span></>}

                            {vTelefonoTrabajo && <><br></br><strong>TEL. TRABAJO</strong><br></br>
                                <span>{vTelefonoTrabajo}</span></>}
                            {vEmail && <>
                                <br></br><strong>E-MAIL</strong><br></br>
                                <span>{vEmail}</span></>}
                        </Tooltip>
                    }
                >
                    {bIsPeriodoPrueba ?
                        <span className="text-danger">
                            <Link to={"/clientes/detalle/" + idClienteAkasia}> <span className="text-danger d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vNombreCliente}</span></Link>
                        </span>
                        :
                        <span>
                            <Link to={"/clientes/detalle/" + idClienteAkasia}> <span className="d-inline-block text-truncate" style={{ maxWidth: '250px' }}>{vNombreCliente}</span></Link>
                        </span>}
                </OverlayTrigger>
            );
        }

    }
];

const LogServicios = () => {

    const [vendedores, setVendedores] = useState([]);
    const [tipoLicencias, setTipoLicencias] = useState([]);
    const [procesandoModal, setProcesandoModal] = useState(false);

    const [logServicios, setLogServicios] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const { token, setToken } = useToken();

    const { FiltroLog, setFiltroLog, resetFiltroLog } = useFiltro();


    const mostrarLogs = async () => {
        setCargando(true);
        setProcesandoModal(true);
        const response = await fetch("/api/logservicios/ObtenerCatalogoLogs?vFiltro=" + encodeURIComponent(JSON.stringify(FiltroLog)),
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const data = await response.json();
            setLogServicios(data["table1"]);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
        setProcesandoModal(false);
    };

    const obtenerDatosBusqueda = async () => {
        setCargando(true);
        const response = await fetch("/api/logservicios/ObtenerDatosBusqueda",
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            const data = await response.json();

            setVendedores(data['table1']);
            setTipoLicencias(data['table2']);
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
        mostrarLogs()
    };

    useEffect(() => {
        obtenerDatosBusqueda();

    }, []);

    return (
        <>
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                        <img src="/img/historial48.png" alt="connectCircle" height={30} />
                    </Col>
                    <Col>
                        <h5>
                            Logs de Servicios de Akasia
                        </h5>
                    </Col>
                </Row>
            </Card>
            <AdvanceTableWrapper
                columns={columns}
                data={logServicios}

                sortable
                pagination
                perPage={15}
            >
                {/*<DepositeStatus />*/}
                <Card className="mb-3">
                    <Card.Header>
                        <Row className="flex-between-center mb-2">
                            <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0">
                                <Form.Select
                                    size="sm"
                                    aria-label="Default select"
                                    value={FiltroLog.vNombreVendedor}
                                    onChange={(event) => { setFiltroLog({ ...FiltroLog, vNombreVendedor: event.target.value }) }}
                                >
                                    <option value="">Todos los vendedores</option>
                                    <option value="NoAsignados">No asignados</option>
                                    {vendedores.map((vendedor, index) => {
                                        return (<option key={index} value={vendedor.vUsuario}>{vendedor.vUsuario}</option>)
                                    })}

                                </Form.Select>

                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0">
                                <Form.Select
                                    size="sm"
                                    aria-label="Default select"
                                    value={FiltroLog.vTipoLicencia}
                                    onChange={(event) => { setFiltroLog({ ...FiltroLog, vTipoLicencia: event.target.value }) }}
                                >
                                    <option value="">Todos las licencias</option>
                                    {tipoLicencias.map((licencia, index) => {
                                        return (<option key={index} value={licencia.idCatTipoLicencia}>{licencia.vDescripcionTipo}</option>)
                                    })}
                                </Form.Select>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0">

                                <Form.Select
                                    size="sm"
                                    value={FiltroLog.vEstatusLicencia}
                                    onChange={(event) => { setFiltroLog({ ...FiltroLog, vEstatusLicencia: event.target.value }) }}
                                    aria-label="Default select"

                                >
                                    <option value="">Todos los estatus</option>
                                    <option value="prueba">Prueba</option>
                                    <option value="activa">Activa</option>
                                    <option value="cancelada">Canceladas</option>
                                </Form.Select>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0">
                                <Form.Control
                                    //onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                    size="sm"
                                    value={FiltroLog.vNombreEmpresa}
                                    aria-label="Default select"
                                    placeholder="Nombre Empresa o Cliente"
                                    onChange={(event) => { setFiltroLog({ ...FiltroLog, vNombreEmpresa: event.target.value.toUpperCase() }) }}
                                >
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row className="flex-between-center">
                            <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0">
                                <Form.Control
                                    size="sm"
                                    aria-label="Default select"
                                    placeholder="Movimiento"
                                    value={FiltroLog.vMovimiento}
                                    onChange={(event) => { setFiltroLog({ ...FiltroLog, vMovimiento: event.target.value.toUpperCase() }) }}
                                >
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} className="d-flex align-items-center pe-0">
                                <div id="orders-actions">
                                    {procesandoModal ? <Button
                                        variant="falcon-default"
                                        size="sm"
                                        transform="shrink-3"
                                        disabled
                                    >
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />&nbsp;
                                        Buscando...
                                    </Button>
                                        : <> <IconButton
                                            variant="falcon-default"
                                            size="sm"
                                            icon="search"
                                            transform="shrink-3"
                                            className="mx-2"
                                            onClick={() => mostrarLogs()}
                                        >
                                            <span className="d-none d-sm-inline-block ms-1">Buscar</span>
                                        </IconButton> <IconButton
                                            variant="falcon-default"
                                            size="sm"
                                            icon="redo-alt"
                                            transform="shrink-3"
                                            className="mx-2"
                                                onClick={() => { resetFiltroLog();  }}
                                        >
                                                <span className="d-none d-sm-inline-block ms-1">Reset</span>
                                            </IconButton></>}


                                </div>
                            </Col>
                            <Col xs={3} sm="auto" className="ms-auto ps-0">
                                &nbsp;
                            </Col>
                        </Row>
                        <Row className="flex-between-center">
                            <Col xs={6} sm="auto" className="ms-auto ps-0">
                                {!cargando &&
                                    <AdvanceTableSearchBox table />
                                }
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                hover: true,
                                className: 'fs--2 mb-0 overflow-hidden'
                            }}
                        />

                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter
                            rowCount={logServicios.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>

            </AdvanceTableWrapper>
            {cargando && (<h5><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h5>)}
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <ToastContainer/>

        </>
    );
};

export default LogServicios;
