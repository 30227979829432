import MetasVenta from 'akasia-components/administradores/MetasVenta/MetasVenta';
import AdministradoresEstadisticas from 'akasia-components/administradores/administradoresEstadisticas';
import ConfirmarEmail from 'akasia-components/authentication/simple/ConfirmarEmail';
import SimpleForgetPassword from 'akasia-components/authentication/simple/ForgetPassword';
import SimpleLogin from 'akasia-components/authentication/simple/Login';
import SimpleLogout from 'akasia-components/authentication/simple/Logout';
import SimplePasswordReset from 'akasia-components/authentication/simple/PasswordReset';
import Clientes from 'akasia-components/cliente/Clientes';
import ClientesInfoIncompleta from 'akasia-components/cliente/ClientesInfoIncompleta';
import ConctactosDuplicados from 'akasia-components/cliente/ConctactosDuplicados';
import DetallesCliente from 'akasia-components/cliente/DetallesCliente';
import NuevoCliente from 'akasia-components/cliente/NuevoCliente';
import Pagos from 'akasia-components/conciliacion/pagos/Pagos';
import DashGeneral from 'akasia-components/dashboards/general';
import Invoice from 'akasia-components/orden/Invoice';

import FacturaDocumentosEcuador from 'akasia-components/factura-ecuador/FacturaDocumentosEcuador';
import FacturaDocumentosGuatemala from 'akasia-components/factura-guatemala/FacturaDocumentosGuatemala';

import PlanesFacturacionElSalvador from 'akasia-components/factura-elsalvador/PlanesFacturacionElSalvador';
import PlanesFacturacionBolivia from 'akasia-components/factura-Bolivia/PlanesFacturacionBolivia';
import PlanesFacturacionPanama from 'akasia-components/factura-Panama/PlanesFacturacionPanama';
import PlanesFacturacionPeru from 'akasia-components/factura-Peru/PlanesFacturacionPeru';
import PlanesFacturacionCostaRica from 'akasia-components/factura-costarica/PlanesFacturacionCostaRica';

import DetallesLicencia from 'akasia-components/licencia/DetallesLicencia';
import EditarUsuarioNubeAkasia from 'akasia-components/licencia/EditarUsuarioNubeAkasia';
import Licencias from 'akasia-components/licencia/Licencias';
import LicenciasInfoIncompleta from 'akasia-components/licencia/LicenciasInfoIncompleta';
import NuevaLicencia from 'akasia-components/licencia/NuevaLicencia';
import NuevaAppComandas from 'akasia-components/licencia/NuevaLicenciaAppComandas';
import NuevaLicenciaCaja from 'akasia-components/licencia/NuevaLicenciaCaja';
import NuevaLicenciaNube from 'akasia-components/licencia/NuevaLicenciaNube';
import NuevaLicenciaServidor from 'akasia-components/licencia/NuevaLicenciaServidor';
import NuevoUsuarioNubeAkasia from 'akasia-components/licencia/NuevoUsuarioNubeAkasia';
import UsuariosNubeAkasia from 'akasia-components/licencia/UsuariosNubeAkasia';
import LogServicios from 'akasia-components/logs/LogServicios';
import Orders from 'akasia-components/orden/CatalogoOrdenes';
import OrderDetails from 'akasia-components/orden/DetalleOrden';
import NuevaOrden from 'akasia-components/orden/NuevaOrden';
import DetallesPoliza from 'akasia-components/poliza/DetallesPoliza';
import RecargasTaecel from 'akasia-components/recargas-taecel/RecargasTaecel';
import CatalogoTicketSoporte from 'akasia-components/soporte-tecnico/CatalogoTicketSoporte';
import DetallesSoporte from 'akasia-components/soporte-tecnico/detalles-soporte/ContactDetails';

import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import useToken from 'hooks/useToken';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';

import Crm from 'akasia-components/dashboards/crm';
import SupportDesk from 'akasia-components/dashboards/support-desk';
import Contacts from 'akasia-components/leads/Contacts';
import Profile from 'akasia-components/profile/Profile';
import MainLayout from 'layouts/MainLayout';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import Notifications from 'akasia-components/notification/NotificationsPage';



const FalconRoutes = () => {


    const { token } = useToken();

    if (!token) {
        return (
            <Section className="py-0">
                <Row className="flex-center min-vh-100 py-6">
                    <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
                        <Logo />
                        <Card>
                            <Card.Body className="p-4 p-sm-5">
                                <SimpleLogin />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Section>
        );
    }




    return (
        <Routes>

            {/*- ------------- Authentication ---------------------------  */}

            {/*- ------------- simple ---------------------------  */}
            <Route element={<AuthSimpleLayout />}>
                <Route path="authentication/simple/login" element={<SimpleLogin />} />

                <Route path="authentication/simple/logout" element={<SimpleLogout />} />
                <Route
                    path="authentication/simple/forgot-password"
                    element={<SimpleForgetPassword />}
                />
                <Route
                    path="authentication/simple/reset-password"
                    element={<SimplePasswordReset />}
                />
                <Route
                    path="authentication/simple/confirm-mail"
                    element={<ConfirmarEmail />}
                />
            </Route>


            {/* //--- MainLayout Starts  */}
            <Route element={<MainLayout />}>

                {/*Dashboard*/}
                <Route path="/" element={<DashGeneral />} />
                {/*  <Route path="dashboard/licencias" element={<DashLicencias />} /> */
                    <Route path="dashboard/crm" element={<Crm />} />
                /*<Route path="dashboard/saas" element={<DashSaas />} />
                <Route path="dashboard/ventas" element={<DashVentas />} />
                <Route path="dashboard/facturacion" element={<DashFacturacion />} />
                <Route path="dashboard/proyecto" element={<DashProyecto />} />*/}
                <Route path="dashboard/soporte" element={<SupportDesk />} />

                {/*Administradores*/}
                <Route path="administradores/administradoresEstadisticas" element={<AdministradoresEstadisticas />} />

                {/*Contactos*/}
                <Route path="contactos" element={<Contacts />} />

                {/*Notificaciones*/}
                <Route path="notificaciones" element={<Notifications />} />

                {/*Contabilidad */}

                <Route path="contabilidad/conciliacion/pagos" element={<Pagos />} />

                {/*Clientes */}
                <Route path="clientes/" element={<Clientes />} />
                <Route path="clientes/detalle/:id" element={<DetallesCliente />} />
                <Route path="clientes/nuevo" element={<NuevoCliente />} />
                <Route path="clientes/informacionincompleta/:vFiltro" element={<ClientesInfoIncompleta />} />
                <Route path="clientes/contactosduplicados/:vFiltro" element={<ConctactosDuplicados />} />

                {/* Ordenes */}
                <Route path="ordenes/detalle/:id" element={<OrderDetails />} />
                <Route path="ordenes/" element={<Orders />} />
                <Route path="ordenes/facturas/detalle/:id" element={<Invoice />} />
                <Route path="ordenes/nueva/:idClienteAkasia?/:idOrden?" element={<NuevaOrden />} />

                {/*Licencias*/}
                <Route path="licencias/" element={<Licencias />} />
                <Route path="licencias/detalle/:id" element={<DetallesLicencia />} />
                <Route path="licencias/nueva/:idClienteAkasia?" element={<NuevaLicencia />} />
                <Route path="licencias/nueva-servidor/:idClienteAkasia?" element={<NuevaLicenciaServidor />} />
                <Route path="licencias/nueva-caja/:idClienteAkasia?" element={<NuevaLicenciaCaja />} />
                <Route path="licencias/nueva-nube/:idClienteAkasia?" element={<NuevaLicenciaNube />} />
                <Route path="licencias/nueva-app-comandas/:idClienteAkasia?" element={<NuevaAppComandas />} />
                <Route path="licencias/informacionIncompleta/:vFiltro" element={<LicenciasInfoIncompleta />} />
                <Route path="logservicios/logs" element={<LogServicios />} />
                <Route path="polizas/detalle/:id" element={<DetallesPoliza />} />


                <Route path="servicios/usuarios-nube-akasia/" element={<UsuariosNubeAkasia />} />
                <Route path="servicios/usuarios-nube-akasia/nueva" element={<NuevoUsuarioNubeAkasia />} />
                <Route path="servicios/usuarios-nube-akasia/editar/:id" element={<EditarUsuarioNubeAkasia />} />
                <Route path="/servicios/facturacion-ecuador" element={<FacturaDocumentosEcuador />} />
                <Route path="/servicios/facturacion-guatemala" element={<FacturaDocumentosGuatemala />} />
                <Route path="/servicios/facturacion-panama" element={<PlanesFacturacionPanama />} />
                <Route path="/servicios/facturacion-peru" element={<PlanesFacturacionPeru />} />
                <Route path="/servicios/facturacion-bolivia" element={<PlanesFacturacionBolivia />} />
                <Route path="/servicios/facturacion-elsalvador" element={<PlanesFacturacionElSalvador />} />
                <Route path="/servicios/facturacion-costarica" element={<PlanesFacturacionCostaRica />} />
                <Route path="/servicios/registro-taecel" element={<RecargasTaecel />} />

                {/* support desk */}
                <Route path="/soporte-tecnico/" element={<CatalogoTicketSoporte />} />
                <Route path="/soporte-tecnico/detalles-soporte/:id" element={<DetallesSoporte />} />



                {/* Metas de venta */}
                <Route path="/metas-de-venta/" element={<MetasVenta />} />
                <Route path="/perfil/" element={<Profile />} />


            </Route>


        </Routes>
    );
};

export default FalconRoutes;
