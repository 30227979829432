import MensajeErrror from 'components/common/MensajeError';
import { ToastContainer } from 'react-toastify';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import FalconCloseButton from '../../components/common/FalconCloseButton';
import ProfileBanner from './Banner';

const Profile = () => {
    const { token, setToken } = useToken();
    const [cargando, setCargando] = useState(true);
    const [procesandoModal, setProcesandoModal] = useState(false);
    const [dataForm, setDataForm] = useState({});
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [perfil, setPerfil] = useState([]);
    const [showModalFoto, setShowModalFoto] = useState(false)
    /**
    * Retorna la informaci�n para mostrar
    */
    const obtenerPerfil = async () => {

        const response = await fetch("/api/perfil/obtenerDatosPerfil/",
            {
                method: 'GET',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== OBTENER INFORMACION DEL PERFIL ====");
            console.log(response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log(detalles);

                setPerfil(detalles['table2'][0]);

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
        setCargando(false);
    };

    //Enviar Datos Para Marcar Pagada
    const onSubmitCargarFoto = async () => {

        setProcesandoModal(true);
        if (dataForm.vArchivo.length == 0) {

            return false;
        }

        var data = new FormData()
        data.append('FormFile', dataForm.vArchivo);

        const response = await fetch("/api/perfil/cargarfoto", {
            method: 'POST',
            body: data,
            headers:
            {
                'Authorization': 'Bearer ' + token
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            if (data.iResponseType == 1) {
                obtenerPerfil();
            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setProcesandoModal(false);
        setShowModalFoto(false);
    };

    useEffect(() => {
        obtenerPerfil();

    }, []);

    return (
        <>
            {cargando ?
                (<h5><Spinner className="text-inline mt-3" animation="border" variant="primary" /> Cargando...</h5>)
                :
                <>
                    <ProfileBanner perfil={perfil} setShowModalFoto={setShowModalFoto} />
                    <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
                    <ToastContainer/>
                </>
            }
            {/* Modal para subir foto */}
            <Modal
                show={showModalFoto}
                onHide={() => setShowModalFoto(false)}
                size="md"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Cambiar foto</Modal.Title>
                    <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" onClick={() => { setShowModalFoto(false); }} />
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Adjuntar fotograf&iacute;a</Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"
                            required
                            onChange={(e) => setDataForm({
                                ...dataForm,
                                vArchivo: e.target.files[0],
                                vNombreDocumento: e.target.files[0].name,
                                vTipoDocumento: e.target.files[0].type,
                                vTamano: e.target.files[0].size
                            })}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setShowModalFoto(false) }} variant='primary' >Cerrar</Button>
                    {procesandoModal ? <Button variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Procesando...
                    </Button> : <Button type="button" onClick={() => onSubmitCargarFoto()} variant='success' >Cambiar Foto</Button>}
                </Modal.Footer>

            </Modal>
        </>

    );
};

export default Profile;
