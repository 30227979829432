import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import useToken from 'hooks/useToken';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getToken, deleteToken } from "firebase/messaging";
import { messaging } from "../../../firebase/firebaseConfig";

const ProfileDropdown = () => {

    const { idUsuario } = useToken();
    const { token, setToken } = useToken();
    const SalirSesion = async () => {
        //hacer cero al sesion en el token de firebase en la bd
        cerrarSesion();
        
    }

    //esta funcion sirve para cerrar la sesion asociada al token de firebase
    const cerrarSesion = async () => {

        var tokenFirebase = '';
        try {
            tokenFirebase = await getToken(messaging, {
                vapidKey: "BDgxI0yryiAvMlu9vmMDZibB-bmaSt5pj1Vrun_IsGWmzS3h5ubI69nRxId9HteusWK3d1cA1_Y1tFMdV4UjUkE",
            });
        } catch (error) { console.error(error); }
        

        console.log("Token Firebase : ", tokenFirebase);

        var jDataSend = {};

        jDataSend.idUsuario = idUsuario;
        jDataSend.tokenFirebase = tokenFirebase;

        const response = await fetch("/api/account/CerrarSesion/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== CERRANDO SESION ====");
            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                setToken(null);

                try {
                    var a = await deleteToken(messaging);
                    console.log(a);
                } catch (error) { console.log(error); }
                
   
                sessionStorage.clear();
                location.href = "/";

            }

        }
        else {
            //setShowMensajeError(true);
            //setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    };


    return (
        <Dropdown navbar={true} as="li">
            <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to="#!"
                className="pe-0 ps-2 nav-link"
            >
                <Avatar src={"/img/team/team_" + idUsuario + ".jpg"} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
                <div className="bg-white rounded-2 py-2 dark__bg-1000">
                    <Dropdown.Item className="fw-bold text-warning" href="#!">
                        <FontAwesomeIcon icon="crown" className="me-1" />
                        <span>Empleado del mes</span>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as={Link} to="/metas-de-venta">
                        Meta de ventas
                    </Dropdown.Item>
                    <Dropdown.Item href="#!">Recibos y Facturas</Dropdown.Item>

                    <Dropdown.Item as={Link} to="/perfil">
                        Configuraci&oacute;n de Perfil
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={SalirSesion}>
                        Salir del sistema
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ProfileDropdown;
