import { useState } from 'react';

export default function useFiltro() {

    /**
     * Filtro Licencias
     */
    const getObjetoVacioLicencia = () => {
        return {
            idCatTipoLicencia: '',
            vEstatusLicencia: '',
            vEstatusPoliza: '',
            vEstatusNube: '',
            vNombreVendedor: '',
            vNombreEmpresa: '',
            vNombreCliente: ''
        }
    }
    const getFiltroLicencias = () => {
        var FiltrolicenciaString = localStorage.getItem('vFiltroLicencias');
        if (FiltrolicenciaString == null)
            FiltrolicenciaString = JSON.stringify(getObjetoVacioLicencia());

        const filtroLicenciaObject = JSON.parse(FiltrolicenciaString);

        return filtroLicenciaObject
    };
    const resetFiltroLicencia = () => {
        saveFiltroLicencias(getObjetoVacioLicencia())
    };
    const saveFiltroLicencias = vFiltro => {
        localStorage.setItem('vFiltroLicencias', JSON.stringify(vFiltro));
        setFiltroLicencia(vFiltro);
    };
    const [FiltroLicencia, setFiltroLicencia] = useState(getFiltroLicencias());

    /**
     * Filtro Logs
     */
    const getObjetoVacioLogs = () => {
        return {
            vTipoLicencia: '',
            vEstatusLicencia: '',
            vNombreVendedor: '',
            vNombreEmpresa: '',
            vMovimiento: ''
        }
    }
    const getFiltroLog = () => {
        var FiltroLogString = localStorage.getItem('vFiltroLog');
        if (FiltroLogString == null)
            FiltroLogString = JSON.stringify(getObjetoVacioLogs());
        const filtroLogObject = JSON.parse(FiltroLogString);
        return filtroLogObject
    };
    const resetFiltroLog = () => {
        saveFiltroLog(getObjetoVacioLogs())
    };
    const saveFiltroLog = vFiltro => {
        localStorage.setItem('vFiltroLog', JSON.stringify(vFiltro));
        setFiltroLog(vFiltro);

    };
    const [FiltroLog, setFiltroLog] = useState(getFiltroLog());

    /**
    * Filtro Cliente
    */
    const getObjetoVacioClientes = () => {
        return {
            vNombreCliente: '',
            vEstatusCliente: '',
            vPais: ''
        }
    }
    const getFiltroCliente = () => {
        var FiltroClienteString = localStorage.getItem('vFiltroCliente');
        if (FiltroClienteString == null)
            FiltroClienteString = JSON.stringify(getObjetoVacioClientes());
        const filtroClienteObject = JSON.parse(FiltroClienteString);
        return filtroClienteObject
    };
    const resetFiltroCliente = () => {
        saveFiltroCliente(getObjetoVacioClientes())
    };
    const saveFiltroCliente = vFiltro => {
        localStorage.setItem('vFiltroCliente', JSON.stringify(vFiltro));
        setFiltroCliente(vFiltro);

    };
    const [FiltroCliente, setFiltroCliente] = useState(getFiltroCliente());

    return {
        setFiltroLicencia: saveFiltroLicencias,
        FiltroLicencia,
        resetFiltroLicencia,
        setFiltroLog: saveFiltroLog,
        FiltroLog,
        resetFiltroLog,
        setFiltroCliente: saveFiltroCliente,
        FiltroCliente,
        resetFiltroCliente

    }
}