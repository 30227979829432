import { useState } from 'react';

export default function useToken() {

    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        
        const userToken = JSON.parse(tokenString);
        
        return userToken?.vToken
    };

    const getName = () => {
        const nameString = localStorage.getItem('token');

        const name = JSON.parse(nameString);

        return name?.vNombre;
    };

    const getNombreCompleto = () => {
        const nameString = localStorage.getItem('token');

        const name = JSON.parse(nameString);

        return name?.vNombre + " " + name?.vApellidos;
    };

    const getIdUsuario = () => {
        const idUsuarioString = localStorage.getItem('token');

        const name = JSON.parse(idUsuarioString);

        return name?.idUsuario;
    };

    const getUsuario = () => {
        const usuarioString = localStorage.getItem('token');

        const usuario = JSON.parse(usuarioString);

        return usuario?.vUsuario;
    };

    const getIdCatTipoUsuario = () => {
        const idCatTipoUsuarioString = localStorage.getItem('token');

        const idCatTipoUsuario = JSON.parse(idCatTipoUsuarioString);

        return idCatTipoUsuario?.idCatTipoUsuario;
    };


    const [usuario, setUsuario] = useState(getUsuario());
    const [idCatTipoUsuario, setIdCatTipoUsuario] = useState(getIdCatTipoUsuario());
    const [name, setName] = useState(getName());

    const [nombreCompleto, setNombreCompleto] = useState(getNombreCompleto());

    const [idUsuario, setIdUsuario] = useState(getIdUsuario());
    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken?.vToken);
    };

    return {
        setToken: saveToken,
        token,
        setName,
        name,
        nombreCompleto,
        setNombreCompleto,
        setIdUsuario,
        idUsuario,
        setUsuario,
        usuario,
        idCatTipoUsuario,
        setIdCatTipoUsuario
    }
}