import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Badge, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import CountUp from 'react-countup';
import DatePicker from "react-datepicker";
import SoftBadge from '../../../../components/common/SoftBadge';
import HalfDoughnutChart from './../HalfDoughnutChart';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const AvanceVentasItemsMes = ({ variablesMetasControl, ObtenerHomeStatiticsAvanceVentas, cargando, vendedores, idCatTipoUsuario, itemsVentasMes }) => {

    const [dateQuarter, setDateQuarter] = useState(new Date())


    return (
        <Card className="h-md-100">
            <Card.Header className="pb-0">

                <Row>
                    <Col md="auto">
                        <Form.Select
                            size="sm"
                            aria-label="Default select"
                            className="mb-1"
                            disabled={idCatTipoUsuario != 1}
                            value={variablesMetasControl.idUsuario}
                            onChange={(e) => {

                                var date_ = new Date(dateQuarter).toLocaleDateString('es-MX', {
                                    year: 'numeric', month: '2-digit', day: '2-digit', hour12: false
                                });

                                ObtenerHomeStatiticsAvanceVentas(date_, e.target.value);
                            }}
                        >
                            <option value="0">Todos los vendedores</option>
                            {vendedores.map((vendedor, index) => {
                                return (<option key={index} value={vendedor.idUsuario}>{vendedor.vNombre} {vendedor.vApellidos}</option>)
                            })}

                        </Form.Select>

                    </Col>

                    <Col>

                        <DatePicker
                            selected={dateQuarter}
                            onChange={(date) => {
                                console.log("date: ", date);
                                //sessionStorage.setItem("selectMes", date);
                                setDateQuarter(date);
                                var date_ = new Date(date).toLocaleDateString('es-MX', {
                                    year: 'numeric', month: '2-digit', day: '2-digit', hour12: false
                                });
                                ObtenerHomeStatiticsAvanceVentas(date_, variablesMetasControl.idUsuario);
                            }}
                            dateFormat="MMMM yyyy - QQQ"
                            className="form-control w-100"
                            showMonthYearPicker />

                    </Col>

                </Row>

                <hr />

                <Row>
                    <Col>
                        <h5 className="mb-0"> <img src="/img/analitycs48.png"></img>Tus Items del Mes</h5>
                    </Col>

                </Row>

            </Card.Header>
            <Card.Body className="pt-0">

                <Row className="align-items-top">
                    {/*{cargando && <h3><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h3>}*/}

                    {true &&
                        <>
                            <Col className="col-12 mt-3">


                                <Row className="g-0">
                                    <Col xs={6} sm={4} md={3} xl={3} xxl={2} className="align-items-center text-center border-300 border-end mb-3" >
                                        <h6 className="pb-0 text-700">
                                            LIC-SERV
                                        </h6>
                                        <span className={
                                            'font-sans-serif fs-2 ' + (itemsVentasMes.iLicServ > 0 ? 'text-success' : 'text-danger')
                                        }>
                                            {cargando ? <Skeleton className="w-75" /> : itemsVentasMes.iLicServ}
                                        </span>
                                        <div>
                                            <h6 className="fs--1 text-500 mb-0 ">

                                                {cargando ? <Skeleton className="w-75" /> :
                                                    <span>
                                                        ${parseFloat(itemsVentasMes.dLicServSubtotal).toLocaleString("es-MX", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2
                                                        })}
                                                    </span>
                                                }



                                            </h6>
                                        </div>
                                    </Col>
                                    <Col xs={6} sm={4} md={3} xl={3} xxl={2} className="align-items-center text-center border-300 border-end" >
                                        <h6 className="pb-0 text-700">
                                            LIC-CAJ
                                        </h6>
                                        <span className={
                                            'font-sans-serif fs-2 ' + (itemsVentasMes.iLicCaj > 0 ? 'text-success' : 'text-danger')
                                        }>
                                            {cargando ? <Skeleton className="w-75" /> : itemsVentasMes.iLicCaj}
                                        </span>
                                        <div>
                                            <h6 className="fs--1 text-500 mb-0 ">
                                                {cargando ? <Skeleton className="w-75" /> :
                                                    <span>
                                                        ${parseFloat(itemsVentasMes.dLicCajSubtotal).toLocaleString("es-MX", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2
                                                        })}
                                                    </span>
                                                }
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col xs={6} sm={4} md={3} xl={3} xxl={2} className="align-items-center text-center border-300 border-end" >
                                        <h6 className="pb-0 text-700">
                                            P&Oacute;LIZAS
                                        </h6>
                                        <span className={
                                            'font-sans-serif fs-2 ' + (itemsVentasMes.iPoliza > 0 ? 'text-success' : 'text-danger')
                                        }>
                                            {cargando ? <Skeleton className="w-75" /> : itemsVentasMes.iPoliza}
                                        </span>
                                        <div>
                                            <h6 className="fs--1 text-500 mb-0 ">
                                                {cargando ? <Skeleton className="w-75" /> :
                                                    <span>
                                                        ${parseFloat(itemsVentasMes.dPolizaSubtotal).toLocaleString("es-MX", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2
                                                        })}
                                                    </span>
                                                }
                                            </h6>
                                        </div>
                                    </Col>

                                    <Col xs={6} sm={4} md={3} xl={3} xxl={2} className="align-items-center text-center border-300 border-end" >
                                        <h6 className="pb-0 text-700">
                                            LIC-NUB
                                        </h6>
                                        <span className={
                                            'font-sans-serif fs-2 ' + (itemsVentasMes.iLicNub > 0 ? 'text-success' : 'text-danger')
                                        }>
                                            {cargando ? <Skeleton className="w-75" /> : itemsVentasMes.iLicNub}
                                        </span>
                                        <div>
                                            <h6 className="fs--1 text-500 mb-0 ">
                                                {cargando ? <Skeleton className="w-75" /> :
                                                    <span>
                                                        ${parseFloat(itemsVentasMes.dLicNubSubtotal).toLocaleString("es-MX", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2
                                                        })}
                                                    </span>
                                                }
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col xs={6} sm={4} md={3} xl={3} xxl={2} className="align-items-center text-center border-300 border-end" >
                                        <h6 className="pb-0 text-700">
                                            LIC-NUB-R
                                        </h6>
                                        <span className={
                                            'font-sans-serif fs-2 ' + (itemsVentasMes.iLicNubRenov > 0 ? 'text-success' : 'text-danger')
                                        }>
                                            {cargando ? <Skeleton className="w-75" /> : itemsVentasMes.iLicNubRenov}
                                        </span>
                                        <div>
                                            <h6 className="fs--1 text-500 mb-0 ">
                                                {cargando ? <Skeleton className="w-75" /> :
                                                    <span>
                                                        ${parseFloat(itemsVentasMes.dLicNubRenovSubtotal).toLocaleString("es-MX", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2
                                                        })}
                                                    </span>
                                                }
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col xs={6} sm={4} md={3} xl={3} xxl={2} className="align-items-center text-center " >
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip style={{ position: 'fixed' }} >
                                                    <p>Se contempla M&oacute;dulos, Timbres MX, Hardware, Usuarios, Servicios.</p>
                                                </Tooltip>
                                            }
                                        >
                                            <h6 className="pb-0 text-700">
                                                MOD-S
                                            </h6>
                                        </OverlayTrigger>
                                        <span className={
                                            'font-sans-serif fs-2 ' + (itemsVentasMes.iMODs > 0 ? 'text-success' : 'text-danger')
                                        }>
                                            {cargando ? <Skeleton className="w-75" /> : itemsVentasMes.iMODs}
                                        </span>
                                        <div>
                                            <h6 className="fs--1 text-500 mb-0 ">
                                                {cargando ? <Skeleton className="w-75" /> :
                                                    <span>
                                                        ${parseFloat(itemsVentasMes.dMODsSubtotal).toLocaleString("es-MX", {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2
                                                        })}
                                                    </span>
                                                }
                                            </h6>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />

                            </Col>
                            <Col xs={7} className="pe-md-0">

                                <Row className="mt-3 mb-4 flex-between-center">
                                    <Col xs={12} sm={12} >

                                        {/*<h5 className="text-800 fs-2">${parseFloat(ventas.nItemsVendidosMes).toLocaleString("es-MX", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2
                                        })} MXN</h5>*/}

                                        {cargando ?
                                            (<h5><Skeleton /></h5>)
                                            :
                                            <h5 className='font-sans-serif fs-2 mb-md-0 mb-lg-2 text-dark'>

                                            </h5>
                                        }

                                        {cargando ?
                                            (<h5><Skeleton /></h5>)
                                            :
                                            (<h5 className={
                                                'font-sans-serif fs-2 mb-md-0 mb-lg-2 ' + (variablesMetasControl.nItemsVendidosMes > 0 ? 'text-success' : 'text-danger')
                                            } >{variablesMetasControl.nItemsVendidosMes} Items</h5>)
                                        }

                                        {cargando ?
                                            <Skeleton />
                                            :
                                            (

                                                variablesMetasControl.nItemsVendidosMes < 22 ?
                                                    <SoftBadge bg="danger" pill>
                                                        <FontAwesomeIcon icon="caret-down" /> Abajo del punto de equilibrio.
                                                    </SoftBadge>
                                                    :
                                                    <SoftBadge bg="success" pill>
                                                        <FontAwesomeIcon icon="caret-up" /> Superaste el punto de equilibrio.
                                                    </SoftBadge>

                                            )

                                        }


                                    </Col>
                                </Row>
                                <Row className="g-0 align-items-center">
                                    <Col xs={4}>
                                        <h5 className="mb-0 text-600">
                                            {
                                                cargando ?
                                                    <Skeleton className="w-50" />
                                                    :
                                                    "Objetivo:"
                                            }
                                        </h5>
                                    </Col>
                                    <Col xs={8}>
                                        {/*<h6 className="mb-0 text-800">${parseFloat(ventas.dMetaMensualItems).toLocaleString("es-MX", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2
                                        })} MXN</h6>*/}

                                        {
                                            cargando ?
                                                <h5 className="mb-0 text-800"><Skeleton /></h5>
                                                :
                                                <>
                                                    <h5 className="mb-0 text-800"><Badge bg="info" pill>{variablesMetasControl.dMetaMensualItems} Items &nbsp; &nbsp; &nbsp; <FontAwesomeIcon icon="code" /> Nivel 1 </Badge></h5>
                                                </>
                                        }

                                    </Col>
                                </Row>

                            </Col>
                            <Col xs={5}>

                                <div
                                    className="ms-auto text-center"
                                    style={{ maxWidth: '10.625rem' }}
                                >
                                    {cargando ?
                                        <div>

                                            <Skeleton circle width={100} height={100} className="mb-1" />
                                            <Skeleton className="w-50" />

                                        </div>

                                        :
                                        (
                                            <div>

                                                <div className="mb-n6">
                                                    <HalfDoughnutChart
                                                        color="primary"
                                                        target={variablesMetasControl.dMetaMensualItems}
                                                        reached={variablesMetasControl.nItemsVendidosMes}
                                                    />
                                                </div>
                                                <CountUp
                                                    start={0}
                                                    end={((variablesMetasControl.nItemsVendidosMes / variablesMetasControl.dMetaMensualItems) * 100).toFixed(0)}
                                                    duration={2.75}
                                                    className="d-inline-block mb-0 mt-n6 fs-1 fw-medium font-sans-serif"
                                                    suffix="%"
                                                    separator=","
                                                />

                                            </div>

                                        )
                                    }
                                </div>


                            </Col>


                        </>
                    }
                </Row>
            </Card.Body>
        </Card>
    );
};
AvanceVentasItemsMes.propTypes = {
    variablesMetasControl: PropTypes.object,
    vendedores: PropTypes.array,
    ObtenerHomeStatiticsAvanceVentas: PropTypes.func,
    cargando: PropTypes.bool,
    idCatTipoUsuario: PropTypes.string,
    itemsVentasMes: PropTypes.object
};
export default AvanceVentasItemsMes;
