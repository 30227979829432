import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import useToken from 'hooks/useToken';
import Avatar from 'components/common/Avatar';
import createMarkup from 'helpers/createMarkup';
import ModalSeguimientoLead from 'akasia-components/leads/ModalSeguimientoLead';
import MensajeErrror from 'components/common/MensajeError';
import MensajeInformativo from 'components/common/MensajeInformativo';
import { Col, Row, Card, /*Button*/ } from 'react-bootstrap';
import Background from '../../components/common/Background';
import greetingImage from '../../assets/img/illustrations/logo_esfera_.fw.png';
import greetingsBg from '../../assets/img/illustrations/ticket-greetings-bg.png';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from 'react-router-dom';

const NotificationItem = ({
    notification,
    setIdLead,
    setShowModalSeguimientoLead,
    setMensajeError,
    setShowMensajeError
    //CrearLbl,
    //infoNoti
}) => {

    const { token } = useToken();
    const objAction = JSON.parse(notification.vJsonAction);
    const navigate = useNavigate();

    //Maneja el click para abrir el modal de seguimiento de lead
    const clickToModalSeguimientoLead = (idLead) => {

        setIdLead(idLead);
        setShowModalSeguimientoLead(true);

        notification.bFueMostrado = true;
        marcarNotificacionComoMostrada(notification.idNotificacion);

    };
    const clickToUrl = () => {

        console.log("objAction: ", objAction);
        notification.bFueMostrado = true;
        marcarNotificacionComoMostrada(notification.idNotificacion);


        navigate(objAction.detalles.vUrl);
    };
    const marcarNotificacionComoMostrada = async (idNotificacion) => {

        var jDataSend = {};
        jDataSend.idNotificacion = idNotificacion;

        const response = await fetch("/api/account/MarcarNotificacionComoMostrada/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== NOTIFICACION MARCADA COMO MOSTRADA ====");

            console.log("response: ", response);

            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {
                //hayNotificacionesNoLeidas();
                //infoNoti.cantidadNoMostradas--;
                //CrearLbl();
            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
        }
    }

    return (
        <div style={{ cursor: 'pointer', border: 'none',   }} 

            className={classNames(
                'p-1 shadow-none notification-item d-flex align-items-center rounded-0 ',
                { 'notification-unread': !notification.bFueMostrado }
                /*className*/
            )}

            onClick={() => {
                //si va mostrar un modal entra aqui
                if (objAction.TypeAction == 1) {

                    //si es seguimiento lead va entrar aqui
                    if (objAction.detalles.idModalType == 1) {
                        clickToModalSeguimientoLead(objAction.detalles.data.idLead);
                    }
                }

                if (objAction.TypeAction == 2) {
                    //si es URL entra aqui
                    clickToUrl();
                }
            }
            }
        >
            {
                <div className="notification-avatar me-3 justify-items-center">
                    <Avatar src={"/img/team/team_" + notification.idUsuarioSource + ".jpg"} size="xl" />
                </div>
            }
            <div className="notification-body" >
                <p className="mb-1" dangerouslySetInnerHTML={createMarkup(notification.vCuerpo)} />
                <span className="notification-time">
                    {notification.time}
                </span>
            </div>
        </div>
    );
};

const NotificationSection = ({
    setIdLead,
    setShowModalSeguimientoLead,
    //idTipoNotificacion,
    setMensajeError,
    setShowMensajeError,
    setMensajeInformativo,
    setShowMensajeInformativo
}) => {

    const { token, setToken } = useToken();

    //const [open, setOpen] = useState(false);

    const [notificaciones, setNotificaciones] = useState([]);
    const [lastNotf, setLastNotf] = useState(0);

    /**
     * Coloca el titulo a la secci�n de notificaicones
     * @returns
     */
    //const getNotificationTitle = () => {
    //    switch (idTipoNotificacion) {
    //        case 1:
    //            return "Leads Liberados";
    //        case 2:
    //            return "Notificaciones Personales";
    //        case 3:
    //            return "Demos instaladas";
    //        default:
    //            return "Otras";
    //    }
    //};

    const obtenerNotificacionesPorCategoria = async () => {

        //setCargando(true);
        var jDataSend = {};
        jDataSend.idCatNotificacion = -1;
        jDataSend.lastNotification = lastNotf;
        jDataSend.iFirstNotification = 0;

        console.log(jDataSend);

        const response = await fetch("/api/account/obtenerNotificacionesPorCategoria/",
        {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jDataSend) 
        }).catch((error) => {

            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            setShowMensajeError(true);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {

            console.log("============ OBTENER NOTIFICACIONES ============");
            console.log("response notificaciones: ", response);
            const data = await response.json();
            console.log("data notificaciones: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);
                ////setNotificaciones(detalles.table2);

                //var nNotfcArray = notificaciones;
                //detalles.table2.map((ntf) => {

                //    nNotfcArray.push(ntf);

                //})
                //setNotificaciones(nNotfcArray);

                setNotificaciones(prevNotificaciones => [
                    ...prevNotificaciones,
                    ...detalles.table2
                ]);

                setLastNotf(detalles.table3[0].minimo);
                //setCargando(false);

            } else {
                console.log("Error: ", data.vMessage);
            }

        }
        else {
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    }

    //const handleVerNotificacionesClick = () => {
    //    if (isFirstClick) {
    //        obtenerNotificacionesPorCategoria();
    //        setIsFirstClick(false); 
    //        setOpen(!open);
    //    }
    //    setOpen(!open);
    //};

    const fetchMoreData = () => {
        obtenerNotificacionesPorCategoria();
    };

    useEffect(() => {
        //CrearLbl();
        obtenerNotificacionesPorCategoria();
    }, []);

    return (
        <>
            <Row className="card shadow-none border-bottom">
                <Col className=" col-12 p-0">
                    {/*<Row className="card-header p-0">*/}
                    {/*    <Col className="col-12">*/}
                    {/*        <h5 className="mb-0">*/}
                    {/*            <Button*/}
                    {/*                //onClick={ () => handleVerNotificacionesClick() }*/}
                    {/*                aria-controls="notifications-collapse-text"*/}
                    {/*                //aria-expanded={open}*/}
                    {/*                className="btn-link dropdown-indicator w-100 text-start"*/}
                    {/*                style={{ textDecoration: 'none', fontSize: '1.2rem', fontWeight: '500' }}*/}
                    {/*                variant="light"*/}
                    {/*            >*/}
                    {/*                {getNotificationTitle()}*/}
                    {/*            </Button>*/}
                    {/*        </h5>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row>*/}
                    {/*    <Col className="col-12">*/}
                    {/*        <span className="ms-3">*/}{/*mensaje*/}{/*</span>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row id={"targetScrollNtf" + idTipoNotificacion} className="card-body p-1 overflow-auto" style={{ height: 250 }}>*/}
                    <Row id={"targetScrollNtf"} className="card-body p-1 overflow-auto m-0" style={{ height: 660 }} >
                        <Col className="notification-section col-12" >
                            {/*<Collapse in={open} className="overflow-auto">*/}
                            {/*<div id="notifications-collapse-text">*/}
                                    {
                                        //cargando ?
                                        //    <NotificationSkeleton></NotificationSkeleton>
                                        //    :
                                            <>
                                                <InfiniteScroll
                                                    dataLength={notificaciones.length}
                                                    next={fetchMoreData}
                                                    hasMore={lastNotf ? true : false}
                                                    loader={<NotificationSkeleton/>}
                                                    scrollableTarget={"targetScrollNtf"}
                                                    scrollThreshold={0.99}
                                                >
                                                    {
                                                        notificaciones.map(notificacion => (
                                                            <NotificationItem
                                                                key={notificacion.idNotificacion}
                                                                notification={notificacion}
                                                                setIdLead={setIdLead}
                                                                setShowModalSeguimientoLead={setShowModalSeguimientoLead}
                                                                setMensajeError={setMensajeError}
                                                                setShowMensajeError={setShowMensajeError}
                                                                setMensajeInformativo={setMensajeInformativo}
                                                                setShowMensajeInformativo={setShowMensajeInformativo}
                                                            //CrearLbl={CrearLbl}
                                                            //infoNoti={infoNoti}
                                                            />
                                                        ))
                                                    }
                                                </InfiniteScroll>
                                            </>
                                            
                                    }
                            {/*    </div>*/}
                            {/*</Collapse>*/}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

const NotificationsPage = () => {

    //const { token, setToken } = useToken();

    const [showModalSeguimientoLead, setShowModalSeguimientoLead] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [mensajeInformativo, setMensajeInformativo] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
    const [showMensajeInformativo, setShowMensajeInformativo] = useState(false);
    const [idLead, setIdLead] = useState(0);


    /**
    * Obtiene TODAS las notificaciones del usuario
    */
    //const obtenerNotificaciones = async () => {

    //    const response = await fetch("/api/account/obtenerTodasLasNotificaciones/",
    //        {
    //            method: 'POST',
    //            headers:
    //            {
    //                'Authorization': 'Bearer ' + token,
    //                'Content-Type': 'application/json'
    //            }
    //        }).catch((error) => {

    //            //setMensajeError('Hubo un problema con la peticion Fetch:' + error);
    //            //setShowMensajeError(true);
    //            console.log('Hubo un problema con la peticion Fetch:' + error);
    //        });

    //    if (response.ok) {

    //        console.log("==== OBTENER TODAS LAS NOTIFICACIONES ====");
    //        console.log("response notificaciones: ", response);
    //        const data = await response.json();
    //        console.log("data notificaciones: ", data);

    //        if (data.iResponseType == 1) {

    //            var detalles = JSON.parse(data.detalles);
    //            console.log("detalles notificaciones: ", detalles);
                
    //            agruparNotificaciones(detalles);

    //        } else {
    //            console.log("Error: ", data.vMessage);
    //        }

    //    }
    //    else {
    //        if (response.status == 401) {
    //            setToken(null);
    //            location.href = "";
    //        }
    //        console.log(response);
    //    }
    //}

    /**
     * Agrupa las notificaciones por su tipo
     * @param {any} notificaciones
     */
    //const agruparNotificaciones = (notificaciones) => {
    //    const notificacionesSeparadas = {};

    //    notificaciones.forEach(notificacion => {
    //        const { idTipoNotificacion, ...restoNotificacion } = notificacion;

    //        if (!notificacionesSeparadas[idTipoNotificacion]) 
    //            notificacionesSeparadas[idTipoNotificacion] = [restoNotificacion];
    //        else 
    //            notificacionesSeparadas[idTipoNotificacion].push(restoNotificacion);
            
    //    });
    //    console.log("==== NOTIFICACIONES SEPARADAS ====");
    //    console.log("notificaciones separadas: ", notificacionesSeparadas);
    //    //setNotificacionesSeparadas(notificacionesSeparadas);
    //    llenarObjeto(notificacionesSeparadas);
    //};

    /**
     * Crea un Objeto con la info que se va colocar en el Lbl debajo de cada categoria de notificai�n
     * Calcula estad�sticas clave sobre notificaciones separadas por tipo.
     * � cantidad de usuarios diferentes con notificaciones de ese tipo
     * � cantidad de notificaciones no leidas
     * � fecha y hora de la ultima notificacion
     */
    //const llenarObjeto = (notificacionesSeparadas) => {
    //    let arregloObjetosFinal = [];

    //    Object.keys(notificacionesSeparadas).forEach(tipo => {
    //        tipo = parseInt(tipo);

    //        let notificacionesTipo = notificacionesSeparadas[tipo];

    //        let idUsuariosSource = new Set();
    //        notificacionesTipo.forEach(notif => {
    //            idUsuariosSource.add(notif.idUsuarioSource);
    //        });

    //        let cantidadNoMostradas = notificacionesTipo.filter(notif => !notif.bFueMostrado).length; 
            
    //        let resultados = {
    //            cantidadUsuarios: idUsuariosSource.size,
    //            cantidadNoMostradas: cantidadNoMostradas,
    //            time: notificacionesTipo[0].time,
    //            vNombre: notificacionesTipo[0].vNombre,
    //            idTipoNotificacion: tipo
    //        };

    //        arregloObjetosFinal.push(resultados);
    //    });

    //    // Imprimir los resultados (opcional para depuraci�n)
    //    console.log("======== RESULTADOS DE OBTENER LA INFORMACION PARA EL LBL ========");
    //    console.log("Resultados: ", arregloObjetosFinal);

    //    //setinfoAdicionalNotificaciones(arregloObjetosFinal);
    //}

    useEffect(() => {
        //obtenerNotificaciones();
    }, []);

    return (
        <>

            <Row>

                <Col className="col-12 mb-3">

                    <Card className="bg-light shadow-none">
                        <Background image={greetingsBg} className="bg-card d-none d-sm-block" />
                        <Card.Header className="d-flex align-items-center z-index-1 p-0">
                            <img src={greetingImage} alt="" width="96" />
                            <div className="ms-n3">
                                <h6 className="mb-1 text-primary">Bienvenido a </h6>
                                <h4 className="mb-0 text-primary fw-bold">
                                    Notificaciones
                                    <span className="text-info fw-medium"> Akasia </span>
                                </h4>
                            </div>
                        </Card.Header>
                    </Card>

                </Col>

                <Col className="col-12 mb-3">

                    <Row>

                        <Col className="col-12">
                            <Card className="h-100">
                                <Card.Body>

                                    <div className="notifications">
                                        <div className="notification-container">
                                            {
                                                
                                                <>
                                                    <NotificationSection
                                                        setShowModalSeguimientoLead={setShowModalSeguimientoLead}
                                                        idTipoNotificacion={1}
                                                        setIdLead={setIdLead}
                                                        setMensajeError={setMensajeError}
                                                        setShowMensajeError={setShowMensajeError}
                                                        setMensajeInformativo={setMensajeInformativo}
                                                        setShowMensajeInformativo={setShowMensajeInformativo}
                                                    />
                                                    {/*<NotificationSection*/}
                                                    {/*    setShowModalSeguimientoLead={setShowModalSeguimientoLead}*/}
                                                    {/*    idTipoNotificacion={2}*/}
                                                    {/*    setIdLead={setIdLead}*/}
                                                    {/*    setMensajeError={setMensajeError}*/}
                                                    {/*    setShowMensajeError={setShowMensajeError}*/}
                                                    {/*    setMensajeInformativo={setMensajeInformativo}*/}
                                                    {/*    setShowMensajeInformativo={setShowMensajeInformativo}*/}
                                                    {/*/>*/}
                                                    {/*<NotificationSection*/}
                                                    {/*    setShowModalSeguimientoLead={setShowModalSeguimientoLead}*/}
                                                    {/*    idTipoNotificacion={3}*/}
                                                    {/*    setIdLead={setIdLead}*/}
                                                    {/*    setMensajeError={setMensajeError}*/}
                                                    {/*    setShowMensajeError={setShowMensajeError}*/}
                                                    {/*    setMensajeInformativo={setMensajeInformativo}*/}
                                                    {/*    setShowMensajeInformativo={setShowMensajeInformativo}*/}
                                                    {/*/>*/}
                                                </>


                                            }
                                        </div>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>

            </Row>


            <ModalSeguimientoLead
                showModal={showModalSeguimientoLead}
                setShowModal={setShowModalSeguimientoLead}
                setMensajeError={setMensajeError}
                setShowMensajeError={setShowMensajeError}
                setMensajeInformativo={setMensajeInformativo}
                setShowMensajeInformativo={setShowMensajeInformativo}
                idLead={idLead}
                setIdLead={setIdLead}
            />

            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <MensajeInformativo setShowMensajeInformativo={setShowMensajeInformativo} showMensajeInformativo={showMensajeInformativo} mensajeInformativo={mensajeInformativo}></MensajeInformativo>
        </>
    );
};

const NotificationSkeleton = () => {
    return (
        <>
            <Row
                style={{ border: 'none' }}
                className={classNames(
                    'p-1 shadow-none  d-flex align-items-center rounded-0',
                    'notification-skeleton'
                )}
            >
                <Col className="col-1 pe-0">
                    <Skeleton circle width={50} height={50} />
                </Col>
                <Col className="col-11 ps-2">
                    <Skeleton height={40} />
                </Col>
            </Row>

            <Row
                style={{ border: 'none' }}
                className={classNames(
                    'p-1 shadow-none  d-flex align-items-center rounded-0',
                    'notification-skeleton'
                )}
            >
                <Col className="col-1 pe-0">
                    <Skeleton circle width={50} height={50} />
                </Col>
                <Col className="col-11 ps-2">
                    <Skeleton height={40} />
                </Col>
            </Row>

            <Row
                style={{ border: 'none' }}
                className={classNames(
                    'p-1 shadow-none  d-flex align-items-center rounded-0',
                    'notification-skeleton'
                )}
            >
                <Col className="col-1 pe-0">
                    <Skeleton circle width={50} height={50} />
                </Col>
                <Col className="col-11 ps-2">
                    <Skeleton height={40} />
                </Col>
            </Row>
        </>
       
    );
};

NotificationSection.propTypes = {
    notifications: PropTypes.array,
    setIdLead: PropTypes.func,
    //idTipoNotificacion: PropTypes.number,
    infoNoti: PropTypes.object,
    setShowModalSeguimientoLead: PropTypes.func,
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    setShowMensajeInformativo: PropTypes.func,
};

NotificationItem.propTypes = {
    notification: PropTypes.object,
    setIdLead: PropTypes.func,
    setShowModalSeguimientoLead: PropTypes.func,
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    setShowMensajeInformativo: PropTypes.func,
    CrearLbl: PropTypes.func,
    infoNoti: PropTypes.object
};

export default NotificationsPage;
